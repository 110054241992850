import { useSidebarStore } from '@/store'

import { LinkbyIcon } from '@/components/svg'

const SidebarLogo = ({ hovered }: { hovered?: boolean }) => {
  const { sidebarType, setCollapsed, collapsed } = useSidebarStore()
  return (
    <div className="px-4 pb-[26px] pt-4">
      <div className="flex items-center">
        <div className="flex flex-1 items-center gap-x-3">
          <LinkbyIcon className="mx-auto size-6 text-primary" />
          {!collapsed && (
            <div className="flex-1 text-xl font-normal text-primary">
              Linkby
            </div>
          )}
        </div>
        {sidebarType === 'classic' && (!collapsed || hovered) && (
          <div className="hidden flex-none lg:block">
            <div
              onClick={() => setCollapsed(!collapsed)}
              className={`size-4 rounded-full border-[1.5px] border-default-900 transition-all duration-150 dark:border-default-200
          ${
            collapsed
              ? ''
              : 'bg-default-900 ring-2 ring-inset ring-default-900  ring-offset-4  dark:ring-offset-default-300'
          }
          `}></div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SidebarLogo
