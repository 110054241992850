import React from 'react'

import { formatCurrency } from '@/lib/locale'

interface MoneyOptions {
  className?: string
  currency?: string
  locale?: string
  children?: string | number | null
  trim?: boolean
}

const Money: React.FC<MoneyOptions> = ({
  locale,
  currency,
  className,
  children,
  trim = true,
}) => {
  const amount = Number(children)

  if (isNaN(amount)) {
    return <>-</>
  }

  if (!currency || !locale) {
    return <>{children}</>
  }

  const formatted = formatCurrency(amount, locale, currency, { trim })

  return <span className={className}>{formatted}</span>
}

export default Money
