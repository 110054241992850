'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { cn, getDynamicPath, isLocationMatch } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'

function LockLink({
  href,
  children,
  subItem,
}: {
  href: string
  children: React.ReactNode
  subItem: any
}) {
  if (subItem.badge) {
    return (
      <span className="flex cursor-not-allowed items-center space-x-3 text-sm opacity-50 transition-all  duration-150">
        <span
          className={` inline-block size-2 flex-none rounded-full border  border-default-600`}></span>
        <div className="flex flex-1  truncate  text-default-600">
          <div className="flex-1  truncate">{subItem.title}</div>
          <Badge className="leading-0 flex-none px-1 text-xs font-normal capitalize">
            {subItem.badge}
          </Badge>
        </div>
      </span>
    )
  } else {
    return <Link href={href}>{children}</Link>
  }
}

const SubMenuItem = ({ subItem }: { subItem: any }) => {
  const pathname = usePathname()
  const locationName = getDynamicPath(pathname)
  return (
    <LockLink href={subItem.href} subItem={subItem}>
      <div
        className={cn(
          'text-sm capitalize  font-normal flex gap-3 items-center transition-all duration-150 rounded dark:hover:text-primary  ',
          {
            'text-linkby-purple': isLocationMatch(subItem.href, locationName),
            'text-default-600 dark:text-default-700': !isLocationMatch(
              subItem.href,
              locationName,
            ),
          },
        )}>
        <span className="flex-1 truncate">{subItem.title}</span>
      </div>
    </LockLink>
  )
}

export default SubMenuItem
