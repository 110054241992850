import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Icon } from '@iconify/react'

import { cn, isLocationMatch } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'

const SingleMenuItem = ({
  item,
  collapsed,
}: {
  item: any
  collapsed: boolean
}) => {
  const { badge, href, title, disabled } = item
  const locationName = usePathname()
  return (
    <Link href={href}>
      <>
        {collapsed ? (
          <div>
            <span
              className={cn(
                'h-12 w-12 mx-auto rounded-md  transition-all duration-300 inline-flex flex-col items-center justify-center  relative  ',
                {
                  'bg-primary text-primary-foreground ': isLocationMatch(
                    href,
                    locationName,
                  ),
                  ' text-default-600  ': !isLocationMatch(href, locationName),
                },
              )}>
              <Icon icon={item.icon} className="size-6" />
            </span>
          </div>
        ) : (
          <div
            className={cn(
              'flex gap-3  text-default-700 text-sm capitalize px-[10px] py-3 rounded',
              disabled
                ? 'cursor-not-allowed text-default-700/50'
                : 'cursor-pointer hover:bg-primary hover:text-primary-foreground',
              {
                'bg-primary text-primary-foreground': isLocationMatch(
                  href,
                  locationName,
                ),
              },
            )}>
            <span className="grow-0">
              <Icon icon={item.icon} className="size-5" />
            </span>
            <div className="text-box grow">{title}</div>
            {badge && <Badge className="rounded">{item.badge}</Badge>}
          </div>
        )}
      </>
    </Link>
  )
}

export default SingleMenuItem
