import React, { createContext, useContext } from 'react'

import { Campaign } from '@/schemas/campaign.schema'

import { useCountryResolver } from '@/hooks/use-country-resolver'

export interface LocalizationOptions {
  locale: string
  currency: string
  timezone: string
  country: string
  currencySymbol: string
}

const LocaleContext = createContext<LocalizationOptions | undefined>(undefined)

export const LocaleProvider = ({
  currency,
  children,
}: {
  currency: string
  children: React.ReactNode
}) => {
  const resolved = useCountryResolver({
    currency: currency || 'AUD',
  })

  const localeOptions: LocalizationOptions = {
    locale: resolved?.locale || 'en-AU',
    currency: resolved?.currency || 'AUD',
    timezone: resolved?.timezone || 'Australia/Sydney',
    country: resolved?.code || 'AU',
    currencySymbol: resolved?.symbol || '$',
  }

  return (
    <LocaleContext.Provider value={{ ...localeOptions }}>
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocale = (): LocalizationOptions | undefined => {
  return useContext(LocaleContext)
}
