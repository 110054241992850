import { Icon } from '@iconify/react'

const Loader = ({
  size = 4,
  className,
  isFinished = false,
}: {
  size?: number
  className?: string
  isFinished?: boolean
}) => {
  return (
    <div
      className={`flex size-full items-center justify-center text-gray-200 ${className || ''}`}>
      <div className="flex flex-row gap-2">
        <Icon
          icon={isFinished ? 'line-md:confirm-circle' : 'svg-spinners:180-ring'}
          width={`${size}rem`}
          height={`${size}rem`}
        />
      </div>
    </div>
  )
}
export default Loader
