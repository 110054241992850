import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getCountries } from '.'

export const useCountriesQuery = () => {
  return useQuery({
    queryKey: ['countries'],
    queryFn: () => getCountries(),
    placeholderData: keepPreviousData,
  })
}
