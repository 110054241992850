'use client'

import { useState } from 'react'
import { Icon } from '@iconify/react'
import { SelectIcon } from '@radix-ui/react-select'

import { affiliatePlatforms } from '@/constants/campaign.constant'

import { cn } from '@/lib/utils'
import useQuery from '@/hooks/use-query'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const SettingsSelection = ({
  label = 'Settings',
  onSelected,
  onCleared,
}: {
  label?: string
  onSelected: (key: string, value: string) => void
  onCleared: () => void
}) => {
  const [open, setOpen] = useState(false)
  const query = useQuery()
  const affiliates: Record<string, string> = {
    awin: 'AWIN',
    rakuten: 'Rakuten',
    shareasale: 'Shareasale',
    cf: 'Commission Factory',
    partnerize: 'Partnerize',
  }

  const settings: Record<
    string,
    {
      label: string
      filterLabel: string
      options: { label: string; value: string }[]
    }
  > = {
    deeplink: {
      label: 'Deeplink Allowed',
      filterLabel:
        query.deeplink && query.deeplink === 'true'
          ? 'Deeplink Allowed'
          : 'Deeplink Not Allowed',
      options: [
        { label: 'All', value: 'all' },
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
    },
    outstandingBudget: {
      label: 'Have Outstanding Budget',
      filterLabel:
        query.outstandingBudget && query.outstandingBudget === 'true'
          ? 'Have Outstanding Budget'
          : 'No Outstanding Budget',
      options: [
        { label: 'All', value: 'all' },
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
    },
    storyLink: {
      label: 'Story Link Published',
      filterLabel:
        query.storyLink && query.storyLink === 'true'
          ? 'Story Link Published'
          : 'Story Link Not Published',
      options: [
        { label: 'All', value: 'all' },
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
    },
    affiliate: {
      label: 'Using Affiliate',
      filterLabel:
        query.affiliate && query.affiliate === 'false'
          ? 'Not Using Affiliate'
          : 'Using Affiliate',
      options: [
        { label: 'All', value: 'all' },
        ...Object.entries(affiliatePlatforms).map(([value, label]) => ({
          label,
          value,
        })),
        { label: 'No', value: 'false' },
      ],
    },
  } as const

  const hasFilter = Object.keys(query).filter((q) =>
    Object.keys(settings || {}).includes(q),
  )

  return settings ? (
    <>
      <DropdownMenu open={open} key={label}>
        <DropdownMenuTrigger onClick={() => setOpen(!open)} asChild>
          <Button
            variant="filterButton"
            className={cn(
              'w-full md:w-auto text-default-500 hover:text-default-500 border justify-normal text-[0.775rem] rounded-full p-0 h-6 pl-1 pr-2 hover:bg-[#f5f6f8] border-[#d8dee4] bg-white',
              hasFilter.length ? 'border' : 'border-dashed',
            )}>
            {hasFilter.length ? (
              <SelectIcon className="mr-[0.35rem]">
                <Icon
                  width={16}
                  onClick={(e) => {
                    onCleared()
                    e.stopPropagation()
                  }}
                  icon="radix-icons:cross-circled"
                />
              </SelectIcon>
            ) : (
              <SelectIcon className="mr-[0.35rem]">
                <Icon className=" size-4" icon="radix-icons:plus-circled" />
              </SelectIcon>
            )}
            {label}
            {hasFilter.map((key) => (
              <span
                key={key}
                className={cn(
                  'border-l ml-2 pl-2',
                  query[key] === 'true' ||
                    (key === 'affiliate' && query[key] !== 'false')
                    ? 'text-green-500'
                    : 'text-red-500',
                )}>
                {settings[key].filterLabel}
                {key === 'affiliate' &&
                  query[key] !== 'false' &&
                  ` - ${affiliates[query[key]]}`}
              </span>
            ))}
            {hasFilter.length ? (
              <SelectIcon className="ml-2">
                <Icon icon="radix-icons:caret-down" width={24} />
              </SelectIcon>
            ) : null}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="start"
          className="w-full"
          onPointerDownOutside={() => setOpen(false)}>
          <div className="flex max-w-lg flex-col gap-2 p-2">
            {Object.keys(settings).map((key) => (
              <div
                key={key}
                className="flex flex-row items-center justify-between gap-2">
                <span className="max-w-sm">{settings[key].label}</span>
                <Select
                  onValueChange={(v) => onSelected(key, v === 'all' ? '' : v)}
                  value={query[key] || 'all'}>
                  <SelectTrigger
                    color="primary"
                    className="w-max rounded-md border-gray-300 ">
                    <SelectValue className="capitalize">
                      {settings[key].options.find((o) => o.value === query[key])
                        ?.label || 'All'}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent className="z-[999999] border-none">
                    {settings[key].options.map((o) => (
                      <SelectItem
                        key={o.value}
                        value={o.value || 'all'}
                        className="capitalize">
                        {o.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  ) : null
}

export default SettingsSelection
