import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getPublisherBrands } from '.'

export const usePublisherBrandsQuery = () => {
  return useQuery({
    queryKey: ['publisher-brands'],
    queryFn: () => getPublisherBrands(),
    placeholderData: keepPreviousData,
  })
}
