import { ReactNode } from 'react'
import { Icon } from '@iconify/react'

import { cn } from '@/lib/utils'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'

interface TabNavOptions {
  tabs: {
    label: string
    value: string
    className?: string
    custom?: ReactNode
    iconOnly?: boolean
    icon?: string
    disabled?: boolean
  }[]
  onTabChange: (value: string) => void
  className?: string
  defaultValue?: string
  value?: string
}

const TabNav = ({
  tabs,
  defaultValue,
  value,
  onTabChange,
  className,
}: TabNavOptions) => {
  return (
    <Tabs
      value={value}
      defaultValue={defaultValue}
      className={`tab-nav ${className || ''}`}
      onValueChange={onTabChange}>
      <TabsList className="tab-nav__list">
        {tabs.map((item, index) =>
          item.custom ? (
            item.custom
          ) : (
            <TabsTrigger
              disabled={item.disabled}
              key={index}
              className={cn('tab-nav__trigger', item.className || '')}
              value={item.value}>
              {!item.iconOnly ? <span>{item.label}</span> : null}
              {item.icon ? <Icon icon={item.icon} /> : null}
            </TabsTrigger>
          ),
        )}
      </TabsList>
    </Tabs>
  )
}

export default TabNav
