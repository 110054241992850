import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  CampaignPubfeedConfigPayload,
  CampaignUpdateRequest,
} from '@/interfaces/campaign.interface'
import { CampaignPublisherBrand } from '@/schemas/campaign-publisher-brand.schema'
import { Campaign } from '@/schemas/campaign.schema'

import {
  approveCampaign,
  endCampaign,
  moveCampaignBackToDraft,
  moveCampaignBackToPending,
  optOutCampaign,
  rejectCampaign,
  updateCampaign,
  updateCampaignBudget,
  updateCampaignMarketplaceConfiguration,
  updateCampaignPeriod,
  updateCampaignPubfeedConfiguration,
  updateCampaignPublisherBrandBudget,
  updateStoryLinks,
} from '.'

export enum CampaignMutationAction {
  Approve = 1,
  Reject = 2,
  End = 3,
  UpdateCampaignBudget = 4,
}

export interface CampaignMutationActionOptions {
  campaign: Campaign
  onSuccess?:
    | ((
        data: any,
        variables: any,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined
  onError?:
    | ((
        error: Error | any,
        variables: any,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined
  refetchCampaign?: boolean
}
export interface CampaignActionOptions<Params>
  extends CampaignMutationActionOptions {
  actionApi: (variables: Params) => Promise<any>
}

export interface CampaignPublisherBrandActionOptions<Params>
  extends CampaignMutationActionOptions {
  campaignPublisherBrand: CampaignPublisherBrand
  actionApi: (variables: Params) => Promise<any>
  refetchCampaignPublisherBrand?: boolean
}

const usePerformCampaignActionMutation = <Params = any>({
  campaign,
  onError,
  onSuccess,
  refetchCampaign = true,
  actionApi,
}: CampaignActionOptions<Params>) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (variables: Params) => actionApi(variables),
    onSuccess: async (data: any, variables: Params, context: unknown) => {
      if (refetchCampaign) {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['campaign_timeline'],
          }),
          queryClient.invalidateQueries({
            queryKey: ['campaigns'],
          }),
        ])
      }
      if (onSuccess) {
        await onSuccess(data, variables, context)
      }
    },
    onError: async (error: Error, variables: Params, context: unknown) => {
      let parsedError: any

      // Attempt to parse the error if it's a stringified server error
      try {
        parsedError = JSON.parse(error.message)
      } catch {}
      if (onError) {
        await onError(parsedError || error, variables, context)
      }
    },
  })
}

const usePerformCampaignPublisherBrandActionMutation = <Params = any>({
  campaign,
  onError,
  onSuccess,
  refetchCampaign = false,
  refetchCampaignPublisherBrand = true,
  actionApi,
}: CampaignPublisherBrandActionOptions<Params>) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (variables: Params) => actionApi(variables),
    onSuccess: async (data: any, variables: Params, context: unknown) => {
      if (refetchCampaignPublisherBrand) {
        await queryClient.invalidateQueries({
          queryKey: ['campaign_publisher_brands'],
        })
      }
      if (refetchCampaign) {
        await queryClient.invalidateQueries({
          queryKey: ['campaigns'],
        })
      }
      if (onSuccess) {
        await onSuccess(data, variables, context)
      }
    },
    onError: async (error: Error, variables: Params, context: unknown) => {
      let parsedError: any

      // Attempt to parse the error if it's a stringified server error
      try {
        parsedError = JSON.parse(error.message)
      } catch {}
      if (onError) {
        await onError(parsedError || error, variables, context)
      }
    },
  })
}

export const useApproveCampaignMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<void>({
    campaign,
    onError,
    onSuccess,
    actionApi: () => approveCampaign(campaign.id),
  })
}

export const useRejectCampaignMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<{ reason: string }>({
    campaign,
    onError,
    onSuccess,
    actionApi: (params) => rejectCampaign(String(campaign.id), params),
  })
}

export const useEndCampaignMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<void>({
    campaign,
    onError,
    onSuccess,
    actionApi: () => endCampaign(String(campaign.id)),
  })
}

export const useUpdateCampaignBudgetMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<{
    marketplace?: number
    pubfeed?: number
    pubfeedUseMaxDeliveredAsBudget?: boolean
  }>({
    campaign,
    onError,
    onSuccess,
    actionApi: (params) => updateCampaignBudget(String(campaign.id), params),
  })
}

export const useOptOutCampaignMutation = ({
  campaign,
  campaignPublisherBrand,
  onError,
  onSuccess,
}: Pick<CampaignMutationActionOptions, 'campaign' | 'onError' | 'onSuccess'> & {
  campaignPublisherBrand: CampaignPublisherBrand
}) => {
  return usePerformCampaignPublisherBrandActionMutation<{
    optOutReason: string
  }>({
    campaign,
    campaignPublisherBrand,
    onError,
    onSuccess,
    actionApi: (params) =>
      optOutCampaign(
        String(campaign.id),
        String(campaignPublisherBrand.brandId),
        params,
      ),
  })
}

export const useUpdateStoryLinksMutation = ({
  campaign,
  campaignPublisherBrand,
  onError,
  onSuccess,
}: Pick<CampaignMutationActionOptions, 'campaign' | 'onError' | 'onSuccess'> & {
  campaignPublisherBrand: CampaignPublisherBrand
}) => {
  return usePerformCampaignPublisherBrandActionMutation<{
    storyLinks: string[]
  }>({
    campaign,
    campaignPublisherBrand,
    onError,
    onSuccess,
    actionApi: (params) =>
      updateStoryLinks(
        String(campaign.id),
        String(campaignPublisherBrand.brandId),
        params,
      ),
  })
}

export const useUpdateCampaignPeriodMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<{
    startDate: string
    availableDate: string
    endDate?: string
  }>({
    campaign,
    onError,
    onSuccess,
    actionApi: (params) => updateCampaignPeriod(String(campaign.id), params),
  })
}

export const useUpdateCampaignMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<CampaignUpdateRequest>({
    campaign,
    onError,
    onSuccess,
    actionApi: (campaignDetails) =>
      updateCampaign(String(campaign.id), campaignDetails),
  })
}

export const useMoveCampaignBackToDraftMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<{ draftReason: string }>({
    campaign,
    onError,
    onSuccess,
    actionApi: (params) => moveCampaignBackToDraft(String(campaign.id), params),
  })
}

export const useMoveCampaignBackToPendingMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<{ pendingReason: string }>({
    campaign,
    onError,
    onSuccess,
    actionApi: (params) =>
      moveCampaignBackToPending(String(campaign.id), params),
  })
}

export const useUpdateCampaignPublisherBrandBudgetMutation = ({
  campaign,
  campaignPublisherBrand,
  onError,
  onSuccess,
}: Pick<CampaignMutationActionOptions, 'campaign' | 'onError' | 'onSuccess'> & {
  campaignPublisherBrand: CampaignPublisherBrand
}) => {
  return usePerformCampaignPublisherBrandActionMutation<{
    budget: number
    useMaxDeliveredAsBudget?: boolean
  }>({
    campaign,
    campaignPublisherBrand,
    onError,
    onSuccess,
    refetchCampaign: true,
    actionApi: (params) =>
      updateCampaignPublisherBrandBudget(
        String(campaign.id),
        String(campaignPublisherBrand.brandId),
        params,
      ),
  })
}

export const useUpdateMarketplaceConfigurationMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<{
    cpc: number
    budget: number
    smartBudget: boolean
  }>({
    campaign,
    onError,
    onSuccess,
    actionApi: (payload) =>
      updateCampaignMarketplaceConfiguration(campaign.id, payload),
  })
}

export const useUpdateCampaignPubfeedConfigurationMutation = ({
  campaign,
  onError,
  onSuccess,
}: Pick<
  CampaignMutationActionOptions,
  'campaign' | 'onError' | 'onSuccess'
>) => {
  return usePerformCampaignActionMutation<CampaignPubfeedConfigPayload>({
    campaign,
    onError,
    onSuccess,
    actionApi: (campaignDetails) =>
      updateCampaignPubfeedConfiguration(String(campaign.id), campaignDetails),
  })
}
