import { keepPreviousData, useQuery } from '@tanstack/react-query'

import {
  AdvertiserFilters,
  downloadInvoice,
  getAdvertiser,
  getAdvertiserBillingDetails,
  getAdvertiserCampaigns,
  getAdvertiserInvoices,
  getAdvertiserPaymentMethods,
  getAdvertisers,
  getAdvertiserUsers,
  getAdvertiserVouchers,
} from '.'

export const useAdvertisersQuery = (filters: AdvertiserFilters) => {
  return useQuery({
    queryKey: ['advertisers', filters],
    queryFn: () => getAdvertisers(filters),
    placeholderData: keepPreviousData,
  })
}

export const useAdvertiserQuery = (advertiserId: string | number) => {
  return useQuery({
    queryKey: ['advertisers', advertiserId],
    queryFn: () => getAdvertiser(advertiserId),
    placeholderData: keepPreviousData,
  })
}

export const useAdvertiserInvoicesQuery = (
  advertiserId: string,
  currency: string,
  page: number,
) =>
  useQuery({
    queryKey: ['advertiser_invoices', advertiserId, currency, page],
    queryFn: () => getAdvertiserInvoices(advertiserId, currency, page),
    placeholderData: keepPreviousData,
  })

export const useDownloadAdvertiserInvoiceQuery = (
  advertiserId: string,
  invoiceId: string,
  options: { enabled: boolean; retry: boolean },
) =>
  useQuery({
    queryKey: ['advertiser_invoice_download', advertiserId, invoiceId],
    queryFn: () => downloadInvoice(advertiserId, invoiceId),
    placeholderData: keepPreviousData,
    enabled: options.enabled,
    retry: options.retry,
  })

export const useAdvertiserVouchersQuery = (
  advertiserId: string,
  currency: string,
  page: number,
) =>
  useQuery({
    queryKey: ['advertiser_vouchers', advertiserId, currency, page],
    queryFn: () => getAdvertiserVouchers(advertiserId, currency, page),
    placeholderData: keepPreviousData,
  })

export const useAdvertiserPaymentMethodsQuery = (advertiserId: string) =>
  useQuery({
    queryKey: ['advertiser_payment_methods', advertiserId],
    queryFn: () => getAdvertiserPaymentMethods(advertiserId),
    placeholderData: keepPreviousData,
  })

export const useAdvertiserBillingDetailsQuery = (advertiserId: string) =>
  useQuery({
    queryKey: ['advertiser_billing_details', advertiserId],
    queryFn: () => getAdvertiserBillingDetails(advertiserId),
    placeholderData: keepPreviousData,
  })

export const useAdvertiserUsersQuery = (
  advertiserId: string,
  type: string,
  page: number,
) =>
  useQuery({
    queryKey: ['advertiser_users', advertiserId, type, page],
    queryFn: () => getAdvertiserUsers(advertiserId, type, page),
    placeholderData: keepPreviousData,
  })

export const useAdvertiserCampaignsQuery = (
  advertiserId: string,
  status: number,
  page: number,
) =>
  useQuery({
    queryKey: ['advertiser_campaigns', advertiserId, status, page],
    queryFn: () => getAdvertiserCampaigns(advertiserId, status, page),
    placeholderData: keepPreviousData,
  })
