'use client'

import { usePathname } from 'next/navigation'
import { Icon } from '@iconify/react'

import { cn, getDynamicPath, isLocationMatch } from '@/lib/utils'

import SubMenuItem from './sub-menu-item'

export const CollapsedHoverMenu = ({ item, menuTitle }: any) => {
  const pathname = usePathname()
  const locationName = getDynamicPath(pathname)

  if (!item?.child) {
    return <div>no menu</div>
  }

  return (
    <ul className="relative space-y-2 before:absolute before:left-4 before:top-0 before:h-[calc(100%-5px)] before:w-[2px] before:rounded before:bg-primary/20">
      <li className="relative flex items-center gap-3 rounded bg-linkby-purple p-3 font-medium text-white">
        <Icon icon={item.icon} className="size-5" />
        {menuTitle}
      </li>
      {item.child.map((subItem: any, j: any) => (
        <li
          className={cn(
            'relative block ml-10 first:pt-4 before:absolute first:before:top-4 before:top-0 before:-left-6 before:w-[2px] before:h-0 before:transition-all before:duration-200',
            {
              'before:bg-linkby-purple first:before:h-[calc(100%-16px)] before:h-full':
                isLocationMatch(subItem.href, locationName),
            },
          )}
          key={`sub_menu_${j}`}>
          <SubMenuItem subItem={subItem} />
        </li>
      ))}
    </ul>
  )
}

export default CollapsedHoverMenu
