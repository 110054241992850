import { useState } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'
import { useForm } from 'react-hook-form'

import { useRejectCampaignMutation } from '@/services/campaigns/mutation.service'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'

import Loader from '../../common/loader'
import { ActionButtonComponent } from '../actions-button'

const RejectCampaignDialog: React.FC<ActionButtonComponent> = ({
  campaign,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const form = useForm({
    defaultValues: {
      reason: '',
    },
  })

  const onSuccess = () => {
    toast({
      title: 'Campaign rejected',
      color: 'success',
      duration: 5000,
    })
    setIsOpen(false)
  }

  const onError = () => {
    toast({
      title:
        'Failed to reject campaign. Please contact tech support if issue persists.',
      color: 'destructive',
    })
    setIsOpen(false)
  }

  const {
    mutate: rejectCampaignMutation,
    isPending,
    isSuccess,
  } = useRejectCampaignMutation({
    campaign,
    onSuccess,
    onError,
  })

  const triggerReject = () => {
    if (form.getValues('reason').trim().length === 0) {
      form.setError('reason', {
        type: 'required',
        message: 'Reason is required to reject the campaign.',
      })
      form.setValue('reason', form.getValues('reason').trim())
    } else {
      rejectCampaignMutation({
        reason: form.getValues('reason').trim(),
      })
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogTitle>Reject Campaign</DialogTitle>
        <p>
          Do you want to reject the campaign <strong>{campaign.name}</strong>?
        </p>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(triggerReject)}>
            <FormField
              name="reason"
              rules={{ required: 'Reason is required' }} // Make reason field required
              render={({ field }) => (
                <FormItem className="pb-3" aria-required="true">
                  <FormLabel className="font-semibold">
                    Reject Reason *
                  </FormLabel>
                  <FormDescription className="text-xs">
                    Please let the advertiser know why their campaign has been
                    rejected.
                  </FormDescription>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Enter reason to reject this campaign"
                    />
                  </FormControl>
                  <FormMessage className="text-red-500">
                    {form.formState.errors.reason?.message}
                  </FormMessage>
                </FormItem>
              )}
            />
            <DialogFooter>
              <div className="flex-end mt-1.5 flex space-x-2">
                <DialogClose asChild>
                  <Button
                    variant="raised"
                    color="default"
                    size="sm"
                    onClick={() => {}}>
                    Cancel
                  </Button>
                </DialogClose>
                <Button
                  color={isSuccess ? 'success' : 'action'}
                  className="relative"
                  size="sm"
                  variant="raised"
                  disabled={isPending || !form.getValues('reason')}>
                  <span className={isPending ? 'invisible' : ''}>
                    {isSuccess ? 'Rejected' : 'Reject'}
                  </span>
                  {isPending ? (
                    <Loader size={1} className="absolute min-h-full" />
                  ) : null}
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default RejectCampaignDialog
