'use client'

import Link from 'next/link'
import { logout } from '@/actions/auth'
import { Icon } from '@iconify/react'
import { Avatar } from '@radix-ui/react-avatar'

import { useGetUser } from '@/services/users/query.service'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import { AvatarFallback, AvatarImage } from '../ui/avatar'

const ProfileInfo = () => {
  const { data: user, isLoading } = useGetUser()

  if (!isLoading && user) {
    return (
      <div className="flex items-center justify-center">
        <span className="mr-2 hidden md:block">
          {user.firstName} {user.lastName}
        </span>
        <DropdownMenu>
          <DropdownMenuTrigger asChild className=" cursor-pointer">
            <div className=" flex items-center  ">
              <Avatar className="size-9">
                {user.avatarUrl ? (
                  <AvatarImage
                    src={user.avatarUrl}
                    alt={`${user.firstName} ${user.lastName}`}
                    width={36}
                    height={36}
                    className="rounded-full"
                  />
                ) : (
                  <AvatarFallback className="rounded-full bg-purple-500 p-2 text-white">
                    {user.firstName[0] + user.lastName[0]}
                  </AvatarFallback>
                )}
              </Avatar>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 p-0" align="end">
            <DropdownMenuLabel className="mb-1 flex items-center gap-2 p-3">
              <Avatar className="size-9">
                {user.avatarUrl ? (
                  <AvatarImage
                    src={user.avatarUrl}
                    alt={`${user.firstName} ${user.lastName}`}
                    width={36}
                    height={36}
                    className="rounded-full"
                  />
                ) : (
                  <AvatarFallback className="rounded-full bg-purple-500 p-2 text-white">
                    {user.firstName[0] + user.lastName[0]}
                  </AvatarFallback>
                )}
              </Avatar>
              <div>
                <div className="text-sm font-medium capitalize text-default-800 ">
                  {user.firstName} {user.lastName}
                </div>
                <Link
                  href="/dashboard"
                  className="text-xs text-default-600 hover:text-primary">
                  {user.email}
                </Link>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuGroup>
              {[
                {
                  name: 'Tech Support',
                  icon: 'line-md:phone-call-loop',
                  href: 'https://linkby.atlassian.net/servicedesk/customer/portal/1',
                  blank: true,
                },
                {
                  name: 'Campaign Dispute Form',
                  icon: 'clarity:form-line',
                  href: 'https://forms.gle/d6NJdPtsa363DgAZ6',
                  blank: true,
                },
              ].map((item, index) => (
                <Link
                  href={item.href}
                  target={item.blank ? '_blank' : '_self'}
                  key={`info-menu-${index}`}
                  className="cursor-pointer">
                  <DropdownMenuItem className="flex cursor-pointer items-center gap-2 px-3 py-1.5 text-sm font-medium capitalize text-default-600 dark:hover:bg-background">
                    <Icon icon={item.icon} className="size-4" />
                    {item.name}
                  </DropdownMenuItem>
                </Link>
              ))}
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onSelect={() => logout()}
              className="my-1 flex cursor-pointer items-center gap-2 px-3 text-sm font-medium capitalize text-default-600 dark:hover:bg-background">
              <Icon icon="line-md:logout" className="size-4" />
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    )
  }
}
export default ProfileInfo
