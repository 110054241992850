'use client'

import { Icon } from '@iconify/react'
import * as HoverCard from '@radix-ui/react-hover-card'

import { cn } from '@/lib/utils'
import { ScrollArea } from '@/components/ui/scroll-area'

import CollapsedHoverMenu from './collapsed-hover-menu'

export const SubMenuHandler = ({
  item,
  toggleSubmenu,
  index,
  activeSubmenu,
  collapsed,
  menuTitle,
}: any) => {
  const { title, disabled, icon } = item

  if (collapsed) {
    return (
      <HoverCard.Root openDelay={0}>
        <HoverCard.Trigger asChild>
          <div
            className={cn(
              'inline-flex cursor-pointer items-center justify-center w-12 h-12 rounded-md',
              disabled
                ? 'cursor-not-allowed text-default-700/50'
                : 'data-[state=open]:bg-primary-100 data-[state=open]:text-primary',
            )}>
            <Icon
              className={cn(
                'size-5 flex items-center justify-center',
                disabled ? 'opacity-50' : 'opacity-100',
              )}
              icon={icon}
            />
          </div>
        </HoverCard.Trigger>
        {!disabled && (
          <HoverCard.Portal>
            <HoverCard.Content
              collisionPadding={70}
              sideOffset={5}
              side="right"
              className="z-50 w-[300px] rounded-md bg-popover shadow-sm data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade data-[state=open]:transition-all">
              <ScrollArea
                className={cn('p-5', {
                  'h-[250px]':
                    item.child &&
                    (item.child.length > 5 ||
                      item.child.some(
                        (child: any) =>
                          child.multi_menu && child.multi_menu.length > 5,
                      )),
                })}>
                <CollapsedHoverMenu item={item} menuTitle={menuTitle} />
              </ScrollArea>
            </HoverCard.Content>
          </HoverCard.Portal>
        )}
      </HoverCard.Root>
    )
  }

  return (
    <div
      onClick={() => !disabled && toggleSubmenu(index)}
      className={cn(
        'flex text-default-700 font-medium text-sm capitalize px-[10px] py-3 rounded transition-all duration-100 group',
        disabled
          ? 'cursor-not-allowed text-default-700/50'
          : 'cursor-pointer hover:bg-background-hover hover:text-linkby-purple',
        {
          'bg-transparent text-linkby-purple font-semibold':
            activeSubmenu === index,
        },
      )}>
      <div className="flex flex-1 items-start gap-3">
        <span className="inline-flex items-center text-lg">
          <Icon
            className="flex size-5 items-center justify-center"
            icon={icon}
          />
        </span>
        <div>{title}</div>
      </div>
      <div className="flex-0">
        <div
          className={cn(
            'text-base rounded-full flex justify-center items-center transition-all duration-300 group-hover:text-linkby-purple',
            {
              'rotate-90': activeSubmenu === index,
              'text-default-500': activeSubmenu !== index,
            },
          )}>
          <Icon
            icon="heroicons:chevron-right-20-solid"
            className={cn('size-5', {
              'cursor-not-allowed text-default-700/50': disabled,
            })}
          />
        </div>
      </div>
    </div>
  )
}

export default SubMenuHandler
