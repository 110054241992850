import { useState } from 'react'
import { Icon } from '@iconify/react'

import { cn } from '@/lib/utils'
import {
  DropdownMenu,
  DropdownMenuCheckboxItemAlternative,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

interface Option {
  label: string
  value: string
}

interface MultipleSelectionProps {
  value: string[]
  label: string
  filter: string
  options: Option[]
  onSelected: (key: unknown, value: unknown) => void
}

const MultipleSelection = ({
  value,
  label,
  filter,
  options,
  onSelected,
}: MultipleSelectionProps) => {
  const [open, setOpen] = useState(false)
  const selectedValues = Array.isArray(value) ? value : [value]

  const handleChange = (optionValue: string) => {
    const newValues = selectedValues.includes(optionValue)
      ? selectedValues.filter((val) => val !== optionValue)
      : [...selectedValues, optionValue]

    onSelected([filter], [newValues])
  }

  return (
    <DropdownMenu open={open} key={label}>
      <DropdownMenuTrigger
        onClick={() => setOpen(!open)}
        className={cn(
          `flex justify-center items-center hover:bg-[#f5f6f8] read-only:bg-white md:max-w-max border border-[#d8dee4] text-default-500 font-semibold text-[0.775rem] rounded-full h-6  p-1 pr-2`,
          !(value || []).length && 'border-dashed',
        )}>
        <Icon
          width={16}
          icon={
            value ? 'radix-icons:cross-circled' : 'radix-icons:plus-circled'
          }
          className="mr-1"
          onClick={(e) => {
            onSelected([filter], [])
            e.stopPropagation()
          }}
        />
        {label}
        {selectedValues.filter((s) => s).length > 0 && (
          <>
            <span className="ml-2 border-l pl-2 text-linkby-purple">
              {options
                .filter(({ value }) => selectedValues.includes(value))
                .map(({ label }) => label)
                .join(' and ')}
            </span>
            <Icon icon="radix-icons:caret-down" width={24} />
          </>
        )}
      </DropdownMenuTrigger>

      <DropdownMenuContent
        align="start"
        className="w-full"
        onInteractOutside={() => setOpen(false)}>
        {options.map(({ label, value: optionValue }) => (
          <DropdownMenuCheckboxItemAlternative
            key={label}
            className="capitalize"
            checked={selectedValues.includes(optionValue)}
            onCheckedChange={() => handleChange(optionValue)}>
            {label}
          </DropdownMenuCheckboxItemAlternative>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default MultipleSelection
