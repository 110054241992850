'use client'

import {
  isServer,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const makeQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  })

let browserQueryClient: QueryClient | undefined = undefined

const getQueryClient = () => {
  if (isServer) {
    return makeQueryClient()
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient()
    return browserQueryClient
  }
}

const ReactQueryClientProvider = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <QueryClientProvider client={getQueryClient()}>
    {children}
  </QueryClientProvider>
)

export default ReactQueryClientProvider
