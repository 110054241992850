'use client'

import { useState } from 'react'
import { useSidebarStore } from '@/store'
import { motion } from 'motion/react'

import { useGetUser } from '@/services/users/query.service'

import { cn } from '@/lib/utils'
import { useMounted } from '@/hooks/use-mounted'
import Header from '@/components/header'
import LayoutLoader from '@/components/layout-loader'
import Sidebar from '@/components/navigation'

const DashBoardLayoutProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { collapsed } = useSidebarStore()
  const [open, setOpen] = useState(false)
  const mounted = useMounted()
  const { isLoading } = useGetUser()

  if (isLoading) {
    return <LayoutLoader />
  }

  return (
    <>
      <Sidebar />
      <div
        className={cn(
          'content-wrapper transition-all duration-150 max-w-full',
          {
            'xl:pl-[248px]': !collapsed,
            'xl:pl-[72px]': collapsed,
          },
        )}>
        <div
          className={cn('min-h-[var(--page-height)] mx-auto max-w-[1280px]')}>
          <div className="px-[15px] 2xl:px-0">
            <Header className="h-[var(--header-height)]" />
            <LayoutWrapper setOpen={setOpen} open={open}>
              {children}
            </LayoutWrapper>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashBoardLayoutProvider

const LayoutWrapper = ({
  children,
  setOpen,
  open,
}: {
  children: React.ReactNode
  setOpen: any
  open: boolean
}) => {
  return (
    <>
      <motion.div
        initial="pageInitial"
        animate="pageAnimate"
        exit="pageExit"
        variants={{
          pageInitial: {
            opacity: 0,
            y: 50,
          },
          pageAnimate: {
            opacity: 1,
            y: 0,
          },
          pageExit: {
            opacity: 0,
            y: -50,
          },
        }}
        transition={{
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.5,
        }}>
        <main>{children}</main>
      </motion.div>
    </>
  )
}
