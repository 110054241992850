'use client'

import { useState } from 'react'
import { Icon } from '@iconify/react'
import { SelectIcon } from '@radix-ui/react-select'
import { format } from 'date-fns'
import { DateRange } from 'react-day-picker'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const DateSelection = ({
  filter,
  value,
  onSelected,
  modes = ['Before', 'After', 'Between'],
  formatter = 'yyyy-MM-dd',
  label = 'Pick date',
}: {
  filter: string
  value: { from: string | null; to: string | null }
  onSelected: (key: any, value: any) => void
  modes?: string[]
  formatter?: string
  label?: string
}) => {
  const currentValues = {
    from: value.from || null,
    to: value.to || null,
  }
  const [mode, setMode] = useState(
    value.from && value.to ? 'Between' : !value.from ? 'Before' : 'After',
  )
  const [date, setDate] = useState(currentValues)
  const [open, setOpen] = useState(false)

  const updateDate = (date: any) => {
    const dates = {
      from:
        mode === 'After'
          ? date || null
          : mode === 'Between'
            ? date?.from || null
            : null,
      to:
        mode === 'Before'
          ? date || null
          : mode === 'Between'
            ? date?.to || date?.from
            : null,
    }

    const finalDates = {
      from: dates.from ? format(dates.from, formatter) : null,
      to: dates.to ? format(dates.to, formatter) : null,
    }

    setDate(finalDates as any)
    onSelected(filter, {
      from: finalDates.from,
      to: finalDates.to,
    })
  }

  const resetValues = (value: any) => {
    setMode(value)
    setDate({
      from: null,
      to: null,
    })
    onSelected(filter, {
      from: null,
      to: null,
    })
  }

  return (
    <>
      <DropdownMenu open={open} key={filter}>
        <DropdownMenuTrigger onClick={() => setOpen(!open)} asChild>
          <Button
            variant="filterButton"
            className={cn(
              'w-full md:w-auto text-default-500 hover:text-default-500 border justify-normal text-[0.775rem] rounded-full p-0 h-6 pl-1 pr-2  hover:bg-[#f5f6f8] border-[#d8dee4] bg-white',
              value.from || value.to ? 'border' : 'border-dashed',
            )}>
            {value.from || value.to ? (
              <SelectIcon className="mr-[0.35rem]">
                <Icon
                  width={16}
                  onClick={(e) => {
                    resetValues('Before')
                    e.stopPropagation()
                  }}
                  icon="radix-icons:cross-circled"
                />
              </SelectIcon>
            ) : (
              <SelectIcon className="mr-[0.35rem]">
                <Icon className=" size-4" icon="radix-icons:plus-circled" />
              </SelectIcon>
            )}
            {label}
            {mode === 'Between' && value.from && value.to ? (
              <span className="ml-2 border-l pl-2 text-linkby-purple">
                Between {format(value.from, 'dd LLL y')} -&nbsp;
                {format(value.to, 'dd LLL y')}
              </span>
            ) : mode === 'After' && value.from ? (
              <span className="ml-2 border-l pl-2 text-linkby-purple">
                Starting from&nbsp;
                {format(value.from, 'dd LLL y')}
              </span>
            ) : (
              value.to && (
                <span className="ml-2 border-l pl-2 text-linkby-purple">
                  Ending on&nbsp;
                  {format(value.to, 'dd LLL y')}
                </span>
              )
            )}
            {(value.from || value.to) && (
              <SelectIcon className="m1-2">
                <Icon icon="radix-icons:caret-down" width={24} />
              </SelectIcon>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="start"
          className="w-full"
          onPointerDownOutside={() => setOpen(false)}>
          <Select onValueChange={resetValues} value={mode}>
            <SelectTrigger
              color="primary"
              className="rounded-md border-gray-300">
              <SelectValue
                placeholder="Select a subject"
                className="capitalize">
                {mode}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="z-[999999] border-none">
              {modes.map((m) => (
                <SelectItem className="capitalize" key={m} value={m}>
                  {m}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {mode === 'Between' ? (
            <Calendar
              mode="range"
              numberOfMonths={2}
              defaultMonth={new Date()}
              selected={(date as unknown as DateRange) || undefined}
              onSelect={updateDate}
              required
            />
          ) : mode === 'After' ? (
            <Calendar
              mode="single"
              numberOfMonths={1}
              defaultMonth={new Date()}
              selected={date.from ? new Date(date.from) : undefined}
              onSelect={updateDate}
              required={false}
            />
          ) : (
            <Calendar
              mode="single"
              numberOfMonths={1}
              defaultMonth={new Date()}
              selected={date.to ? new Date(date.to) : undefined}
              onSelect={updateDate}
              required={false}
            />
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default DateSelection
