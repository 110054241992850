'use client'

import { useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import { useSidebarStore, useThemeStore } from '@/store'
import { Icon } from '@iconify/react'

import { menusConfig } from '@/config/menu.config'
import { cn, getDynamicPath, isLocationMatch } from '@/lib/utils'
import { useMediaQuery } from '@/hooks/use-media-query'
import { ScrollArea } from '@/components/ui/scroll-area'

import SidebarLogo from './logo'
import NestedSubMenu from './nested-menus'
import SingleMenuItem from './single-menu-item'
import SubMenuHandler from './sub-menu-handler'

const menus = menusConfig || []

interface SidebarProps {
  className?: string
}

const Sidebar = ({ className }: SidebarProps) => {
  const isMobile = useMediaQuery('(max-width: 1280px)')
  const { collapsed, setCollapsed, sidebarBg, mobileMenu, setMobileMenu } =
    useSidebarStore()
  const { layout } = useThemeStore()
  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null)

  const toggleSubmenu = (i: number) => {
    if (activeSubmenu === i) {
      setActiveSubmenu(null)
    } else {
      setActiveSubmenu(i)
    }
  }

  const pathname = usePathname()
  const locationName = isMobile ? pathname : getDynamicPath(pathname)

  useEffect(() => {
    let subMenuIndex = null
    menus?.map((item: any, i: number) => {
      if (item?.child) {
        item.child.map((childItem: any) => {
          if (isLocationMatch(childItem.href, locationName)) {
            subMenuIndex = i
          }
        })
      }
    })
    setActiveSubmenu(subMenuIndex)
  }, [locationName, mobileMenu, setMobileMenu])

  const sidebarContent = (
    <div
      className={cn(
        'fixed top-0',
        {
          // NOTE: Desktop specific classes
          'w-[248px]': !isMobile && !collapsed,
          'w-[72px]': !isMobile && collapsed,
          'm-6 bottom-0 rounded-md': !isMobile && layout === 'semibox',
          'h-full': !isMobile && layout !== 'semibox',
          'border-r': !isMobile,
          // NOTE:  Mobile specific classes
          'h-full w-[248px] z-[9999]': isMobile,
          '-left-[300px] invisible opacity-0': isMobile && !mobileMenu,
          'left-0 visible opacity-100': isMobile && mobileMenu,
        },
        className,
        'bg-card',
      )}>
      {!isMobile && (
        <Icon
          onClick={() => setCollapsed(!collapsed)}
          className="bg-default absolute right-0 top-1/2 z-[99999] h-[30px] w-[20px] -translate-y-1/2 cursor-pointer rounded-l-[4px] text-zinc-300 transition-all duration-150 hover:text-linkby-purple dark:bg-default-100 hover:dark:bg-primary hover:dark:text-gray-900"
          icon={`lucide:chevrons-${collapsed ? 'right' : 'left'}`}
        />
      )}
      {isMobile && sidebarBg !== 'none' && (
        <div
          className="absolute left-0 top-0 z-[-1] size-full bg-cover bg-center opacity-[0.07]"
          style={{ backgroundImage: `url(${sidebarBg})` }}
        />
      )}
      <SidebarLogo hovered={collapsed} />
      <ScrollArea
        className={cn('sidebar-menu h-[calc(100%-80px)]', {
          'px-2': !isMobile && !collapsed,
          'px-4': isMobile && !collapsed,
        })}>
        <ul
          className={cn('space-y-1', {
            'space-y-2 text-center': collapsed,
          })}>
          {menus.map((item, i) => (
            <li key={`menu_key_${i}`}>
              {!item.child && !item.isHeader && (
                <SingleMenuItem item={item} collapsed={collapsed} />
              )}
              {item.isHeader && !item.child && !collapsed && (
                <div className="mb-3 mt-4 text-xs font-semibold uppercase text-default-900">
                  {item.title}
                </div>
              )}
              {item.child && (
                <>
                  <SubMenuHandler
                    item={item}
                    toggleSubmenu={toggleSubmenu}
                    index={i}
                    activeSubmenu={activeSubmenu}
                    collapsed={collapsed}
                    menuTitle={item.title}
                  />
                  {!collapsed && (
                    <NestedSubMenu
                      activeSubmenu={activeSubmenu}
                      item={item}
                      index={i}
                    />
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </ScrollArea>
    </div>
  )

  return (
    <>
      {sidebarContent}
      {isMobile && mobileMenu && (
        <div
          onClick={() => setMobileMenu(false)}
          className="overlay fixed inset-0 z-[999] bg-black/60 opacity-100 backdrop-blur-sm"
        />
      )}
    </>
  )
}

export default Sidebar
