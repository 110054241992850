'use client'

import { JSX } from 'react'
import { useSidebarStore } from '@/store'

import { useMediaQuery } from '@/hooks/use-media-query'

import MobileSidebar from './mobile-sidebar'
import PopoverSidebar from './popover'

const Sidebar = () => {
  const { sidebarType } = useSidebarStore()

  const isDesktop = useMediaQuery('(min-width: 1280px)')

  let selectedSidebar = null

  if (!isDesktop && (sidebarType === 'popover' || sidebarType === 'classic')) {
    selectedSidebar = <MobileSidebar />
  } else {
    const sidebarComponents: { [key: string]: JSX.Element } = {
      popover: <PopoverSidebar />,
    }

    selectedSidebar = sidebarComponents[sidebarType]
  }

  return <div>{selectedSidebar}</div>
}

export default Sidebar
