import * as React from 'react'
import { Icon } from '@iconify/react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const badgeVariants = cva(
  'flex w-max items-center rounded-full border px-[3px] py-[2px] pr-2 text-xs font-semibold transition-colors',
  {
    variants: {
      color: {
        gray: 'border-gray-200 bg-gray-200 text-gray-700',
        yellow: 'border-yellow-200 bg-yellow-200 text-yellow-700',
        green: 'border-green-200 bg-green-200 text-green-700',
        blue: 'border-blue-200 bg-blue-200 text-blue-700',
        red: 'border-red-200 bg-red-200 text-red-700',
      },
      outline: {
        true: 'bg-outline',
      },
    },
    compoundVariants: [
      {
        color: 'gray',
        outline: true,
        class: 'border-gray-500 text-gray-500',
      },
      {
        color: 'yellow',
        outline: true,
        class: 'border-yellow-500 text-yellow-500',
      },
      {
        color: 'green',
        outline: true,
        class: 'border-green-500 text-green-500',
      },
      {
        color: 'blue',
        outline: true,
        class: 'border-blue-500 text-blue-500',
      },
      {
        color: 'red',
        outline: true,
        class: 'border-red-500 text-red-500',
      },
    ],
    defaultVariants: {
      color: 'gray',
      outline: false,
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  color?: 'red' | 'green' | 'gray' | 'yellow' | 'blue'
  icon?: 'check' | 'xmark' | 'circle' | 'exclamation' | 'clock'
  ghost?: boolean
  size?: number
}

export const icons = {
  check: 'fa6-solid:circle-check',
  xmark: 'fa6-solid:circle-xmark',
  circle: 'fa6-solid:circle',
  exclamation: 'fa6-solid:circle-exclamation',
  clock: 'fa6-solid:clock',
  trash: 'fa6-solid:box-archive',
}

function AccessibleBadge({
  className,
  children,
  color = 'gray',
  icon = 'circle',
  size = 4,
  outline = false,
  ...props
}: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ color, outline }), className)}
      {...props}>
      <Icon icon={icons[icon]} className={cn(`mr-1 size-${size}`)} />
      {children}
    </div>
  )
}

export { AccessibleBadge, badgeVariants }
