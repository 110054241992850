import React from 'react'

import { cn } from '@/lib/utils'

import { Input } from './input'

interface TimePickerProps {
  value?: string
  onChange?: (time: string) => void
  className?: string
}

const TimePicker: React.FC<TimePickerProps> = ({
  value,
  onChange,
  className,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value)
    }
  }

  return (
    <div className={cn('flex space-x-2', className)}>
      <Input value={value} onChange={handleChange} type="time" />
    </div>
  )
}

export { TimePicker }
