import { CampaignStatusEnum } from '@/enums/campaign.enum'
import { statuses } from '@/constants/campaign.constant'

import { AccessibleBadge, BadgeProps } from '@/components/ui/accessible-badge'

const iconAndColorMap = {
  [CampaignStatusEnum.Draft]: { icon: 'circle', color: 'gray' },
  [CampaignStatusEnum.Pending]: { icon: 'exclamation', color: 'yellow' },
  [CampaignStatusEnum.Active]: { icon: 'check', color: 'green' },
  [CampaignStatusEnum.Rejected]: { icon: 'xmark', color: 'red' },
  [CampaignStatusEnum.Finished]: { icon: 'clock', color: 'blue' },
}

const CampaignStatus = ({
  status,
  size = 3,
  className,
  outline = false,
}: {
  status: CampaignStatusEnum
  size?: number
  className?: string
  outline?: boolean
}) => {
  return (
    <AccessibleBadge
      color={iconAndColorMap[status].color as BadgeProps['color']}
      size={size}
      icon={iconAndColorMap[status].icon as BadgeProps['icon']}
      outline={outline}
      className={className}>
      {statuses[status]}
    </AccessibleBadge>
  )
}

export default CampaignStatus
