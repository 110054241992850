import React from 'react'

import { formatDate } from '@/lib/locale'

interface DateProps {
  children?: string | number | Date | null
  className?: string
  locale: string
  timezone: string
  dateOnly?: boolean
  showTimezone?: boolean
}

const Date: React.FC<DateProps> = ({
  children,
  timezone,
  locale,
  dateOnly,
  showTimezone,
  className,
}) => {
  if (!children) {
    return <>-</>
  }

  if (!timezone) {
    return <>{String(children)}</>
  }

  const formattedDate = formatDate(children, timezone, locale, {
    dateOnly,
    showTimezone,
  })

  return <span className={className}>{formattedDate}</span>
}

export default Date
