'use edge'

import { useEffect, useState } from 'react'

import { useCountriesQuery } from '@/services/countries/query.service'

import { Country } from '@/schemas/country.schema'

export function useCountries() {
  const { data, isLoading, isError } = useCountriesQuery()
  const [countries, setCountries] = useState<Country[]>(data?.data || [])
  const [loading, setLoading] = useState(isLoading)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (data) {
      setCountries(data.data)
      setLoading(false)
    }
    if (isError) {
      setError(new Error('Failed to fetch countries'))
      setLoading(false)
    }
  }, [data, isError])

  return { countries, loading, error }
}
