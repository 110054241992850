import { useState } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'
import { useForm } from 'react-hook-form'

import { useMoveCampaignBackToDraftMutation } from '@/services/campaigns/mutation.service'

import { statuses } from '@/constants/campaign.constant'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'

import Loader from '../../common/loader'
import { ActionButtonComponent } from '../actions-button'

const MoveCampaignBackToDraftDialog: React.FC<ActionButtonComponent> = ({
  campaign,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const form = useForm({
    defaultValues: {
      draftReason: '',
    },
  })

  const onSuccess = () => {
    toast({
      title: 'Campaign moved back to draft',
      color: 'success',
      duration: 5000,
    })
    setIsOpen(false)
  }

  const onError = async (error: any) => {
    try {
      if ((error?.data?.errors ?? []).length) {
        error.data.errors.map((x: any) => {
          if (x.field.includes('draftReason')) {
            form.setError('draftReason', { message: x.message })
          }
        })
      } else {
        throw error
      }
    } catch (e) {
      toast({
        title:
          'Failed to move campaign back to draft. Please contact tech support if issue persists.',
        color: 'destructive',
      })
      setIsOpen(false)
    }
  }

  const {
    mutate: moveCampaignBackToDraftMutation,
    isPending,
    isSuccess,
  } = useMoveCampaignBackToDraftMutation({
    campaign,
    onSuccess,
    onError,
  })

  const triggerMoveBackToDraft = () => {
    const trimmedReason = form.getValues('draftReason').trim()
    const wordCount = trimmedReason
      .split(/\s+/)
      .filter((word) => word.length > 0).length

    if (trimmedReason.length === 0) {
      form.setError('draftReason', {
        type: 'required',
        message: 'Reason is required to move the campaign back to draft.',
      })
      form.setValue('draftReason', trimmedReason)
    } else if (wordCount < 3) {
      form.setError('draftReason', {
        type: 'minWords',
        message: 'Please provide at least 3 words for the reason.',
      })
    } else {
      moveCampaignBackToDraftMutation({
        draftReason: trimmedReason,
      })
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogTitle>Move Campaign Back to Draft</DialogTitle>
        <p>
          Do you want to change the campaign status from{' '}
          {statuses[campaign.status]} to Draft now?
        </p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(triggerMoveBackToDraft)}>
            <FormField
              name="draftReason"
              rules={{ required: 'Reason is required' }} // Make reason field required
              render={({ field }) => (
                <FormItem className="pb-3" aria-required="true">
                  <FormLabel className="font-semibold">Reason *</FormLabel>
                  <FormDescription className="text-xs">
                    Please provide a reason (minimum 3 words) for moving the
                    campaign back to draft.
                  </FormDescription>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Enter reason to move the campaign back to draft"
                    />
                  </FormControl>
                  <FormMessage className="text-red-500">
                    {form.formState.errors.draftReason?.message}
                  </FormMessage>
                </FormItem>
              )}
            />
            <DialogFooter>
              <div className="flex-end flex space-x-2">
                <DialogClose asChild>
                  <Button color="default" size="sm" variant="raised">
                    Cancel
                  </Button>
                </DialogClose>
                <Button
                  color={isSuccess ? 'success' : 'action'}
                  className="relative"
                  size="sm"
                  variant="raised"
                  disabled={isPending}>
                  <span className={isPending ? 'invisible' : ''}>
                    Change Status
                  </span>
                  {isPending ? (
                    <Loader size={1} className="absolute min-h-full" />
                  ) : null}
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default MoveCampaignBackToDraftDialog
