'use client'

import { useSidebarStore } from '@/store'
import { Menu } from 'lucide-react'

import { Button } from '@/components/ui/button'

const MobileMenuHandler = () => {
  const { mobileMenu, setMobileMenu } = useSidebarStore()
  return (
    <div>
      <Button
        onClick={() => setMobileMenu(!mobileMenu)}
        variant="ghost"
        size="icon"
        className="relative size-9 rounded-full text-default-500 hover:bg-primary-100 hover:text-primary dark:text-default-800 dark:hover:bg-default-300">
        <Menu className="size-5 " />
      </Button>
    </div>
  )
}

export default MobileMenuHandler
