import {
  CampaignPlacement,
  CampaignPublisherBrandStatusEnum,
  CampaignStatusEnum,
} from '@/enums/campaign.enum'

export const statuses: {
  [key: number | string]: string
} = {
  [CampaignStatusEnum.Draft]: 'Draft',
  [CampaignStatusEnum.Pending]: 'Pending',
  [CampaignStatusEnum.Active]: 'Active',
  [CampaignStatusEnum.Finished]: 'Finished',
  [CampaignStatusEnum.Rejected]: 'Rejected',
}

export const publisherBrandStatuses: {
  [key: string]: string
} = {
  [CampaignPublisherBrandStatusEnum.Pitched]: 'Invited',
  [CampaignPublisherBrandStatusEnum.Accepted]: 'Accepted',
  [CampaignPublisherBrandStatusEnum.Declined]: 'Declined',
  [CampaignPublisherBrandStatusEnum.OptedOut]: 'Opted out',
}

export const campaignPlacements: Record<CampaignPlacement, string> = {
  [CampaignPlacement.Core]: 'Marketplace',
  [CampaignPlacement.Pubfeed]: 'Pubfeed',
  [CampaignPlacement.Social]: 'Social',
  [CampaignPlacement.Newsletter]: 'Newsletter',
}

export const affiliatePlatforms: Record<string, string> = {
  awin: 'AWIN',
  cf: 'Commission Factory',
  rakuten: 'Rakuten',
  shareasale: 'ShareASale',
  partnerize: 'Partnerize',
  impact: 'Impact',
}

// Minimum number of clicks to be considered a publisher brand when reducing budget
// i.e. current spend + clicks to threshold * cpc >= new budget
export const CAMPAIGN_PUBLISHER_BRAND_THRESHOLD_CLICKS = 50
export const CAMPAIGN_PUBFEED_THRESHOLD_CLICKS = 50
