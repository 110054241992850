import { Icon } from '@iconify/react'

import { formatNumber } from '@/lib/locale'
import { Input } from '@/components/ui/input'

export interface PaginatorProps {
  pages: number
  items: number
  entityName: string
  entityPlural: string
  pageValue: number
  handlePage: (newValue: number) => void
}

export const Paginator = ({
  pages,
  items,
  entityName,
  entityPlural,
  pageValue,
  handlePage,
}: PaginatorProps) => {
  const handlePageNav = (direction: 'next' | 'prev') => {
    const newValue =
      direction === 'next'
        ? Math.min(Number(pageValue) + 1, pages || 1)
        : Math.max(Number(pageValue) - 1, 1)

    handlePage(newValue)
  }

  if (pages === 0 || items === 0) return <></>

  return (
    <div className="sticky bottom-0 flex h-12 w-full items-center justify-between border-t bg-white px-2 dark:bg-muted">
      <span>
        {items > 1
          ? `${formatNumber(items.toString(), 'en-US')} ${entityPlural} found`
          : `${formatNumber(items.toString(), 'en-US')} ${entityName} found`}
      </span>
      <div>
        {pages && pages > 1 ? (
          <div className="ml-auto flex items-center">
            <Icon
              icon="heroicons:chevron-left"
              className="mr-2 size-4 cursor-pointer"
              onClick={() => handlePageNav('prev')}
            />
            Page
            <Input
              radius="sm"
              size="sm"
              min={1}
              max={pages}
              value={pageValue}
              className="mx-2 w-12"
              onChange={(e) => handlePage(Number(e.target.value) || 1)}
            />
            of {pages}
            <Icon
              icon="heroicons:chevron-right"
              className="ml-2 size-4 cursor-pointer"
              onClick={() => handlePageNav('next')}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
