import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface HeaderTopNavigatorState {
  routeQueryParams: {
    [pathname: string]: { [key: string]: string }
  }
  setRouteQueryParams: (v: HeaderTopNavigatorState['routeQueryParams']) => void
}

export const useHeaderTopNavigatorStore = create<HeaderTopNavigatorState>()(
  persist(
    (set) => ({
      routeQueryParams: {},
      setRouteQueryParams: (
        value: HeaderTopNavigatorState['routeQueryParams'],
      ) => set({ routeQueryParams: value }),
    }),
    {
      name: 'header-breadcrumb-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
