import { ChevronLeft, ChevronRight } from 'lucide-react'
import { DayPicker } from 'react-day-picker'

import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'

export type CalendarProps = React.ComponentProps<typeof DayPicker>
function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-0 md:p-3', className)}
      classNames={{
        button_next: cn(
          buttonVariants({ variant: 'ghost', color: 'primary' }),
          'h-7 w-7 p-0  right-0',
        ),
        button_previous: cn(
          buttonVariants({ variant: 'ghost', color: 'primary' }),
          'h-7 w-7 p-0  left-0',
        ),
        caption_label: 'text-sm font-medium',
        day: 'flex-1 text-center rounded text-sm p-0 relative [&:has([aria-selected])]:bg-primary [&:has([aria-selected])]:rounded-md focus-within:relative focus-within:z-20',
        day_button:
          'w-full h-10 p-0 font-normal aria-selected:opacity-100 text-current',
        disabled: 'text-muted-foreground opacity-50',
        hidden: 'invisible',
        month: 'space-y-3',
        month_caption: 'flex justify-center pt-1 relative items-center',
        month_grid: 'w-full border-collapse',
        months:
          'w-full relative flex w-full [&>*:nth-child(odd):not(nav)]:ml-5',
        nav: 'flex justify-between absolute w-full z-[1]',
        outside: 'text-muted-foreground opacity-50',
        range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        selected: `text-primary-foreground hover:text-primary-foreground focus:text-primary-foreground bg-linkby-purple rounded-md`,
        today: 'bg-accent text-accent-foreground font-semibold',
        week: 'flex w-full gap-1 mt-2',
        weekday:
          'flex-1 text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        weekdays: 'flex',
        ...classNames,
      }}
      components={{
        Chevron: (props) => {
          if (props.orientation === 'left') {
            return <ChevronLeft {...props} className="size-4" />
          }
          return <ChevronRight {...props} className="size-4" />
        },
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
