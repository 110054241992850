import { TableCell, TableRow } from '@/components/ui/table'

export const DataTableEmptyRow = ({
  colSpan,
  message,
}: {
  colSpan?: number
  message?: string
}) => {
  return (
    <TableRow className="bg-card">
      <TableCell
        colSpan={colSpan || 1}
        className="px-4 py-3 text-center text-card-foreground">
        {message || 'No results found.'}
      </TableCell>
    </TableRow>
  )
}
