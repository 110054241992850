'use client'

import React, { useState } from 'react'
import { usePathname } from 'next/navigation'
import { useSidebarStore } from '@/store'

import { menusConfig } from '@/config/menu.config'
import { cn, isLocationMatch } from '@/lib/utils'
import { ScrollArea } from '@/components/ui/scroll-area'

import SidebarLogo from '../common/logo'
import MenuLabel from '../common/menu-label'
import NestedSubMenu from '../common/nested-menus'
import SingleMenuItem from './single-menu-item'
import SubMenuHandler from './sub-menu-handler'

const menus = menusConfig || []

const MobileSidebar = ({ className }: { className?: string }) => {
  const { sidebarBg, mobileMenu, setMobileMenu } = useSidebarStore()
  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null)
  const [activeMultiMenu, setMultiMenu] = useState<number | null>(null)
  const { collapsed } = useSidebarStore()

  const toggleSubmenu = (i: number) => {
    if (activeSubmenu === i) {
      setActiveSubmenu(null)
    } else {
      setActiveSubmenu(i)
    }
  }

  const toggleMultiMenu = (subIndex: number) => {
    if (activeMultiMenu === subIndex) {
      setMultiMenu(null)
    } else {
      setMultiMenu(subIndex)
    }
  }
  const locationName = usePathname()

  React.useEffect(() => {
    let subMenuIndex = null
    let multiMenuIndex = null
    menus?.map((item: any, i: number) => {
      if (item?.child) {
        item.child.map((childItem: any, j: number) => {
          if (isLocationMatch(childItem.href, locationName)) {
            subMenuIndex = i
          }
          if (childItem?.multi_menu) {
            childItem.multi_menu.map((multiItem: any, k: number) => {
              if (isLocationMatch(multiItem.href, locationName)) {
                subMenuIndex = i
                multiMenuIndex = j
              }
            })
          }
        })
      }
    })
    setActiveSubmenu(subMenuIndex)
    setMultiMenu(multiMenuIndex)
    if (mobileMenu) {
      setMobileMenu(false)
    }
  }, [locationName, mobileMenu, setMobileMenu])
  return (
    <>
      <div
        className={cn(
          'fixed top-0  bg-card h-full w-[248px] z-[9999] ',
          className,
          {
            ' -left-[300px] invisible opacity-0  ': !mobileMenu,
            ' left-0 visible opacity-100  ': mobileMenu,
          },
        )}>
        {sidebarBg !== 'none' && (
          <div
            className="absolute left-0 top-0   z-[-1] size-full bg-cover bg-center opacity-[0.07]"
            style={{ backgroundImage: `url(${sidebarBg})` }}></div>
        )}
        <SidebarLogo hovered={collapsed} />
        <ScrollArea
          className={cn('sidebar-menu  h-[calc(100%-80px)] ', {
            'px-4': !collapsed,
          })}>
          <ul
            className={cn('', {
              ' space-y-2 text-center': collapsed,
            })}>
            {menus.map((item, i) => (
              <li key={`menu_key_${i}`}>
                {/* single menu  */}

                {!item.child && !item.isHeader && (
                  <SingleMenuItem item={item} collapsed={collapsed} />
                )}

                {/* menu label */}
                {item.isHeader && !item.child && !collapsed && (
                  <MenuLabel item={item} />
                )}

                {/* sub menu */}
                {item.child && (
                  <>
                    <SubMenuHandler
                      item={item}
                      toggleSubmenu={toggleSubmenu}
                      index={i}
                      activeSubmenu={activeSubmenu}
                      collapsed={collapsed}
                    />

                    {!collapsed && (
                      <NestedSubMenu
                        toggleMultiMenu={toggleMultiMenu}
                        activeMultiMenu={activeMultiMenu}
                        activeSubmenu={activeSubmenu}
                        item={item}
                        index={i}
                        title={''}
                      />
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
        </ScrollArea>
      </div>
      {mobileMenu && (
        <div
          onClick={() => setMobileMenu(false)}
          className="overlay fixed inset-0 z-[999] bg-black/60 opacity-100 backdrop-blur-sm"></div>
      )}
    </>
  )
}

export default MobileSidebar
