import { useSidebarStore } from '@/store'
import { Icon } from '@iconify/react'

export default function CollapseMenu() {
  const { collapsed, setCollapsed } = useSidebarStore()
  return (
    <Icon
      onClick={() => setCollapsed(!collapsed)}
      className="bg-default absolute right-0 top-1/2 z-[99999] h-[30px] w-[20px] -translate-y-1/2 cursor-pointer rounded-l-[4px] text-zinc-300  transition-all duration-150 hover:text-linkby-purple dark:bg-default-100 hover:dark:bg-primary hover:dark:text-gray-900"
      icon={`flowbite:chevron-double-${collapsed ? 'right' : 'left'}-outline`}
    />
  )
}
