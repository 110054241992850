'use client'

import { useState } from 'react'
import { Icon } from '@iconify/react'
import { SelectIcon } from '@radix-ui/react-select'
import { CleaveOptions } from 'cleave.js/options'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { CleaveInput } from '@/components/ui/cleave'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

export interface ValueRange {
  min: string | undefined | null
  max: string | undefined | null
}

type Mode = 'gte' | 'lte' | 'between'

interface NumberSelectionProps {
  filter: string
  value: ValueRange
  onSelected: (filter: string, value: ValueRange) => void
  modes?: Mode[]
  label?: string
}

const NumberSelection = ({
  filter,
  value,
  onSelected,
  modes = ['gte', 'lte', 'between'],
  label = 'Set Values',
}: NumberSelectionProps) => {
  const [mode, setMode] = useState<Mode>(
    value.min && value.max ? 'between' : value.max ? 'lte' : 'gte',
  )
  const [open, setOpen] = useState(false)
  const [formatted, setFormatted] = useState<ValueRange>({
    min: null,
    max: null,
  })
  const options: CleaveOptions = {
    prefix: '$',
    numeral: true,
    numeralDecimalMark: '.',
    delimiter: ',',
    numeralPositiveOnly: true,
    noImmediatePrefix: true,
  }
  const modeMapping: Record<Mode, string> = {
    gte: 'Greater than or equal to',
    lte: 'Less than or equal to',
    between: 'Between',
  }

  const resetValues = () => {
    onSelected(filter, {
      min: null,
      max: null,
    })
  }

  const clearFilters = (e: any) => {
    resetValues()
    e.stopPropagation()
  }

  return (
    <>
      <DropdownMenu open={open} key={filter}>
        <DropdownMenuTrigger onClick={() => setOpen(!open)} asChild>
          <Button
            variant="filterButton"
            className={cn(
              'w-full md:w-auto text-default-500 hover:text-default-500 border justify-normal text-[0.775rem] rounded-full p-0 h-6 pl-1 pr-2  hover:bg-[#f5f6f8] border-[#d8dee4] bg-white',
              value.min || value.max ? 'border' : 'border-dashed',
            )}>
            {value.min || value.max ? (
              <SelectIcon className="mr-[0.35rem]">
                <Icon
                  width={16}
                  onClick={clearFilters}
                  icon="radix-icons:cross-circled"
                />
              </SelectIcon>
            ) : (
              <SelectIcon className="mr-[0.35rem]">
                <Icon className=" size-4" icon="radix-icons:plus-circled" />
              </SelectIcon>
            )}
            {label}
            {value.min && value.max ? (
              <span className="ml-2 border-l pl-2 text-linkby-purple">
                {options.prefix}
                {Number(value.min).toLocaleString()} to&nbsp;
                {options.prefix}
                {Number(value.max).toLocaleString()}
              </span>
            ) : value.max ? (
              <span className="ml-2 border-l pl-2 text-linkby-purple">
                Less than&nbsp;{options.prefix}
                {Number(value.max).toLocaleString()}
              </span>
            ) : value.min ? (
              <span className="ml-2 border-l pl-2 text-linkby-purple">
                More than&nbsp;{options.prefix}
                {Number(value.min).toLocaleString()}
              </span>
            ) : null}
            {(value.min || value.max) && (
              <SelectIcon className="m1-2">
                <Icon icon="radix-icons:caret-down" width={24} />
              </SelectIcon>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="start"
          className="flex w-full items-center justify-center gap-1"
          onPointerDownOutside={() => setOpen(false)}>
          <Select
            onValueChange={(value: any) => {
              resetValues()
              setMode(value)
            }}
            value={mode}>
            <SelectTrigger
              size="sm"
              radius="sm"
              className="group mr-0 max-w-max border-none text-[0.775rem] font-semibold hover:bg-primary/80 hover:text-primary-foreground">
              <SelectValue placeholder="Select a mode">
                {modeMapping[mode]}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="z-[999999] border-none">
              {modes.map((m) => (
                <SelectItem key={m} value={m}>
                  {modeMapping[m]}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {(mode === 'between' || mode === 'gte') && (
            <CleaveInput
              id="budget_min_prefix"
              key="budget_min_prefix"
              className="h-8"
              placeholder={options.prefix}
              options={options}
              onChange={(e: any) => {
                setFormatted({ max: value.max || null, min: e.target.value })
                onSelected(filter, {
                  min: e.target.rawValue.replace(/\D/g, '') || null,
                  max: value.max || null,
                })
              }}
              value={value.min?.toString()}
            />
          )}
          {(mode === 'between' || mode === 'lte') && (
            <>
              {mode === 'between' && <span>and</span>}
              <CleaveInput
                id="budget_max_prefix"
                key="budget_max_prefix"
                className="h-8"
                placeholder={options.prefix}
                options={options}
                value={value.max?.toString()}
                onChange={(e: any) => {
                  setFormatted({ min: value.min || null, max: e.target.value })
                  onSelected(filter, {
                    min: value.min || null,
                    max: e.target.rawValue.replace(/\D/g, '') || null,
                  })
                }}
              />
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default NumberSelection
