import { useState } from 'react'
import { useLocale } from '@/providers/locale.provider'
import { DialogClose } from '@radix-ui/react-dialog'
import { TZDate } from 'react-day-picker'
import { useForm } from 'react-hook-form'

import { useUpdateCampaignPeriodMutation } from '@/services/campaigns/mutation.service'

import { formatTime24Hour } from '@/lib/locale'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { TimePicker } from '@/components/ui/time-picker'
import { toast } from '@/components/ui/use-toast'

import Loader from '../../common/loader'
import { ActionButtonComponent } from '../actions-button'
import CampaignReactivationNotice from './common/reactivation-notice'

const UpdateCampaignPeriodDialog: React.FC<ActionButtonComponent> = ({
  campaign,
  children,
  preOpen = false,
  onClose = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(preOpen)
  const locale = useLocale()

  const currentAvailableDate = new Date(campaign?.brief?.availableDate)
  const currentStartDate = new Date(campaign?.brief?.startDate)
  const currentEndDate = campaign?.brief?.endDate
    ? new Date(campaign?.brief?.endDate)
    : null

  const onSuccess = () => {
    toast({
      title: 'Campaign timeframe updated successfully',
      color: 'success',
      duration: 5000,
    })
    handleOpenChange(false)
  }

  const onError = async (error: any) => {
    try {
      if ((error?.data?.errors ?? []).length) {
        error.data.errors.map((x: any) => {
          if (x.field.includes('startDate')) {
            form.setError('startDate', { message: x.message })
          }
          if (x.field.includes('endDate')) {
            form.setError('endDate', { message: x.message })
          }
        })
      }
    } catch (e) {
      toast({
        title:
          'Failed to update campaign timeframe. Please contact tech support if issue persists.',
        color: 'destructive',
      })
      handleOpenChange(false)
    }
  }

  const form = useForm({
    defaultValues: {
      availableDate: currentAvailableDate.toISOString(),
      availableTime: formatTime24Hour(
        currentAvailableDate,
        locale!.timezone,
        locale!.locale,
      ),
      startDate: currentStartDate.toISOString(),
      startTime: formatTime24Hour(
        currentStartDate,
        locale!.timezone,
        locale!.locale,
      ),
      endDate: currentEndDate ? currentEndDate.toISOString() : null,
      endTime: currentEndDate
        ? formatTime24Hour(currentEndDate, locale!.timezone, locale!.locale)
        : null,
    },
  })

  const { mutate: updateCampaignPeriod, isPending: isUpdatingCampaignPeriod } =
    useUpdateCampaignPeriodMutation({
      campaign,
      onError,
      onSuccess,
    })

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open)
    form.reset()
    if (!open) {
      onClose() // Emit onClose event when dialog is closed
    }
  }

  function mergeDateAndTime(date: TZDate, time: string): Date {
    const clonedDate = new TZDate(date.getTime(), locale!.timezone)
    const [hours, minutes] = time.split(':').map(Number)
    clonedDate.setHours(hours, minutes, 0, 0)
    return new Date(clonedDate.getTime())
  }

  const validateDates = (
    availableDate: Date,
    startDate: Date,
    endDate: Date | null,
  ) => {
    if (endDate && startDate >= endDate) {
      form.setError('startDate', {
        message: 'Start date must be earlier than end date',
      })
      form.setError('endDate', {
        message: 'End date must be later than start date',
      })
      return false
    }

    if (availableDate > startDate) {
      form.setError('availableDate', {
        message: 'Available date must be equal to or earlier than start date',
      })
      form.setError('startDate', {
        message: 'Start date must be equal to or later than available date',
      })
      return false
    }

    if (endDate && endDate < new Date()) {
      form.setError('endDate', {
        message: 'End date must be equal to or later than current date',
      })
      return false
    }

    return true
  }

  const triggerSubmit = () => {
    const availableDate = mergeDateAndTime(
      new TZDate(form.getValues('availableDate'), locale!.timezone),
      form.getValues('availableTime') as string,
    )
    const startDate = mergeDateAndTime(
      new TZDate(form.getValues('startDate'), locale!.timezone),
      form.getValues('startTime') as string,
    )
    const endDate =
      form.getValues('endDate') && form.getValues('endTime')
        ? mergeDateAndTime(
            new TZDate(form.getValues('endDate') as string, locale!.timezone),
            form.getValues('endTime') as string,
          )
        : null

    if (!validateDates(availableDate, startDate, endDate)) {
      return
    }

    updateCampaignPeriod({
      availableDate: availableDate.toISOString(),
      startDate: startDate.toISOString(),
      endDate: endDate?.toISOString(),
    })
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="min-w-[880px]">
        <DialogTitle>Update Timeframe</DialogTitle>
        <Form {...form}>
          <CampaignReactivationNotice campaign={campaign} />
          <form onSubmit={form.handleSubmit(triggerSubmit)}>
            <div className="mt-4 grid grid-cols-3 gap-4">
              <div>
                <label
                  className="mb-2 inline-block text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-50"
                  htmlFor="availableDate">
                  Available date
                </label>
                <div className="rounded-md border p-2">
                  <FormField
                    name="availableDate"
                    rules={{ required: 'Available date is required' }}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormControl>
                          <Calendar
                            className="w-full p-0 md:p-0"
                            mode="single"
                            selected={new TZDate(field.value, locale!.timezone)}
                            onSelect={(date) => {
                              if (!date) return
                              field.onChange(date.toISOString())
                            }}
                            defaultMonth={
                              new TZDate(field.value, locale!.timezone)
                            }
                            timeZone={locale!.timezone}
                            modifiers={{
                              current: [
                                new TZDate(
                                  currentAvailableDate,
                                  locale!.timezone,
                                ),
                              ],
                            }}
                            modifiersClassNames={{
                              current: 'bg-gray-200',
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="availableTime"
                    rules={{ required: 'Available time is required' }}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="my-2">Time</FormLabel>
                        <FormControl>
                          <TimePicker
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
                <FormMessage className="text-red-500">
                  {form.formState.errors.availableDate?.message}
                  {form.formState.errors.availableTime?.message}
                </FormMessage>
              </div>
              <div>
                <label
                  className="mb-2 inline-block text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-50"
                  htmlFor="startDate">
                  Start date
                </label>
                <div className="rounded-md border p-2">
                  <FormField
                    name="startDate"
                    rules={{ required: 'Start date is required' }}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormControl>
                          <Calendar
                            className="w-full p-0 md:p-0"
                            mode="single"
                            selected={new TZDate(field.value, locale!.timezone)}
                            onSelect={(date) => {
                              if (!date) return
                              field.onChange(date.toISOString())
                            }}
                            defaultMonth={
                              new TZDate(field.value, locale!.timezone)
                            }
                            timeZone={locale!.timezone}
                            modifiers={{
                              current: [
                                new TZDate(currentStartDate, locale!.timezone),
                              ],
                            }}
                            modifiersClassNames={{
                              current: 'bg-gray-200',
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="startTime"
                    rules={{ required: 'Start time is required' }}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="my-2">Time</FormLabel>
                        <FormControl>
                          <TimePicker
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
                <FormMessage className="text-red-500">
                  {form.formState.errors.startTime?.message}
                  {form.formState.errors.startDate?.message}
                </FormMessage>
              </div>
              <div>
                <label
                  className="mb-2 inline-block text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-50"
                  htmlFor="endDate">
                  End date
                </label>
                <div className="rounded-md border p-2">
                  <FormField
                    name="endDate"
                    rules={{ required: 'End date is required' }}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormControl>
                          <Calendar
                            mode="single"
                            className="w-full p-0 md:p-0"
                            selected={new TZDate(field.value, locale!.timezone)}
                            defaultMonth={
                              new TZDate(field.value, locale!.timezone)
                            }
                            onSelect={(date) => {
                              if (!date) return
                              field.onChange(date.toISOString())
                            }}
                            timeZone={locale!.timezone}
                            modifiers={{
                              current: currentEndDate
                                ? [new TZDate(currentEndDate, locale!.timezone)]
                                : [],
                            }}
                            modifiersClassNames={{
                              current: 'bg-gray-200',
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="endTime"
                    rules={{ required: 'End time is required' }}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="my-2">Time</FormLabel>
                        <FormControl>
                          <TimePicker
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
                <FormMessage className="text-red-500">
                  {form.formState.errors.endTime?.message}
                  {form.formState.errors.endDate?.message}
                </FormMessage>
              </div>
            </div>
            <p className="mt-2 text-sm text-muted-foreground">
              The date and time above are displayed in the campaign timezone:{' '}
              {locale!.timezone}.
            </p>

            <div className="flex w-full justify-end space-x-2 pt-8">
              <DialogClose asChild>
                <Button
                  size="sm"
                  variant="raised"
                  color="default"
                  onClick={() => {}}>
                  Cancel
                </Button>
              </DialogClose>
              <Button
                size="sm"
                color="action"
                variant="raised"
                type="submit"
                disabled={isUpdatingCampaignPeriod}>
                <span className={isUpdatingCampaignPeriod ? 'invisible' : ''}>
                  Update
                </span>
                {isUpdatingCampaignPeriod ? (
                  <Loader size={1} className="absolute min-h-full" />
                ) : null}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateCampaignPeriodDialog
