import { useState } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'

import { useEndCampaignMutation } from '@/services/campaigns/mutation.service'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { toast } from '@/components/ui/use-toast'

import { ActionButtonComponent } from '../actions-button'

const EndCampaignDialog: React.FC<ActionButtonComponent> = ({
  campaign,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onSuccess = () => {
    toast({
      title: 'Campaign ended successfully',
      color: 'success',
      duration: 5000,
    })
    setIsOpen(false)
  }

  const onError = () => {
    toast({
      title:
        'Failed to end campaign. Please contact tech support if issue persists.',
      color: 'destructive',
    })
    setIsOpen(false)
  }

  const { mutate: endCampaignMutation, isPending } = useEndCampaignMutation({
    campaign,
    onSuccess,
    onError,
  })

  const triggerEndCampaign = () => endCampaignMutation()

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogTitle>End campaign now</DialogTitle>
        <p>
          Are you sure you want to end the campaign{' '}
          <strong>{campaign.name}</strong>?
        </p>
        <DialogFooter>
          <div className="flex-end mt-2 flex space-x-2">
            <DialogClose asChild>
              <Button size="sm" color="default" variant="raised">
                Cancel
              </Button>
            </DialogClose>
            <Button
              color="destructive"
              variant="raised"
              size="sm"
              onClick={triggerEndCampaign}
              disabled={isPending}>
              End now
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EndCampaignDialog
