'use client'

import { useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'

import errorImage from '@/assets/images/error/500.png'
import { Button } from '@/components/ui/button'

interface ErrorProps {
  error: Error & { digest?: string }
  reset: () => void
}

const Error = ({ error, reset }: ErrorProps) => {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <div className="flex min-h-screen items-center justify-center overflow-y-auto p-10">
      <div className="flex w-full flex-col items-center">
        <div className="max-w-[740px]">
          <Image
            src={errorImage}
            alt="Error Image"
            className="size-full object-cover"
            priority
          />
        </div>
        <div className="mt-16 text-center">
          <div className="text-2xl font-semibold text-default-900 md:text-4xl lg:text-5xl">
            Something Went Wrong
          </div>
          <div className="mt-9 flex justify-center gap-4">
            <Button
              onClick={reset}
              color="action"
              size="lg"
              className="md:min-w-[140px]">
              Try Again
            </Button>
            <Button
              color="action"
              asChild
              className="md:min-w-[140px]"
              size="lg">
              <Link href="/">Go to Homepage</Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
