import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'

import { CampaignStatusEnum } from '@/enums/campaign.enum'
import { Campaign } from '@/schemas/campaign.schema'

const CampaignReactivationNotice: React.FC<{ campaign: Campaign }> = ({
  campaign,
}) => {
  if (campaign.status !== CampaignStatusEnum.Finished) {
    return null
  }

  if (!campaign.brief.endDate) {
    return null
  }

  const endDate = new Date(campaign.brief.endDate)

  if (endDate > new Date()) {
    return null
  }

  return (
    <div className="flex gap-2 border-l-4 border-yellow-500 bg-yellow-100 p-4 text-[13px] text-yellow-700">
      <div className="pt-0.5">
        <Icon icon="heroicons:exclamation-triangle" className="size-5" />
      </div>
      <div>
        <p>
          Campaign is not active. To reactivate please ensure the following:
        </p>
        <ul className="list-outside list-disc pl-4 pt-2">
          <li>
            Adjusting campaign&apos;s end date to future date will reactivate
            the campaign
          </li>
          <li>
            Adjusting publisher brand and or pubfeed budget to greater than
            currently delivered by them will ensure campaign is actively
            delivering budget (Optional)
          </li>
        </ul>
      </div>
    </div>
  )
}

export default CampaignReactivationNotice
