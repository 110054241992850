'use client'

import Image from 'next/image'
import Link from 'next/link'

import errorImage from '@/assets/images/error/404.png'
import { Button } from '@/components/ui/button'

const NotFound = () => {
  return (
    <div className="flex min-h-screen items-center justify-center overflow-y-auto p-10">
      <div className="flex w-full flex-col items-center">
        <div className="max-w-[740px]">
          <Image
            src={errorImage}
            alt="Error Image"
            className="size-full object-cover"
          />
        </div>
        <div className="mt-16 text-center">
          <div className="text-2xl font-semibold text-default-900 md:text-4xl lg:text-5xl">
            Ops! Page Not Found
          </div>
          <div className="mt-3 text-sm text-default-600 md:text-base">
            The page you are looking for might have been removed had <br /> its
            name changed or is temporarily unavailable.
          </div>
          <Button
            color="action"
            asChild
            className="mt-9 md:min-w-[300px]"
            size="lg">
            <Link href="/">Go to Homepage</Link>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NotFound
