import { useState } from 'react'
import { Icon } from '@iconify/react'
import { SelectIcon } from '@radix-ui/react-select'

import { cn } from '@/lib/utils'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const SingleSelection = ({
  value,
  label,
  filter,
  options,
  onSelected,
}: {
  value: string
  label: string
  filter: string
  options: { label: string; value: string }[]
  onSelected: (key: any, value: any) => void
}) => {
  const [open, setOpen] = useState(false)
  return (
    <Select
      key={value}
      open={open}
      value={options.find((o) => o.value === value)?.value}
      onValueChange={(v) => {
        onSelected(filter, v)
        setOpen(false)
      }}>
      <SelectTrigger
        icon={null}
        onClick={() => setOpen(!open)}
        className={cn(
          `group hover:bg-[#f5f6f8] read-only:bg-white md:max-w-max font-semibold text-[0.775rem] rounded-full h-6  border-[#d8dee4] p-1 pr-2`,
          !value ? 'border-dashed' : 'border',
        )}
        size="sm"
        radius="sm">
        <SelectIcon
          className="mr-[0.35rem]"
          title={`Clear ${filter.charAt(0).toUpperCase() + filter.slice(1)} Filter`}>
          <Icon
            onClick={(e) => {
              if (value) {
                onSelected(filter, '')
                e.stopPropagation()
              }
            }}
            width={16}
            icon={
              value ? 'radix-icons:cross-circled' : 'radix-icons:plus-circled'
            }
          />
        </SelectIcon>
        <SelectValue placeholder={label}>
          {label}
          <span className="ml-2 border-l pl-2 text-linkby-purple">
            {value
              ? `${options.find((o: any) => o.value === value)!.label}`
              : ''}
          </span>
        </SelectValue>
        {value && (
          <SelectIcon className="m1-2">
            <Icon icon="radix-icons:caret-down" width={24} />
          </SelectIcon>
        )}
      </SelectTrigger>
      <SelectContent onPointerDownOutside={() => setOpen(false)}>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default SingleSelection
