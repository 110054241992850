'use client'

import { useMediaQuery } from '@/hooks/use-media-query'

import ClassicHeader from './layout/classic-header'
import MobileMenuHandler from './mobile-menu-handler'
import ProfileInfo from './profile-info'
import TopNavigator from './top-navigator'

const Header = ({ className }: { className?: string }) => {
  const isDesktop = useMediaQuery('(min-width: 1280px)')

  return (
    <ClassicHeader className={className}>
      <div className="flex h-full items-center justify-between bg-card py-3">
        <TopNavigator />
        <div className="nav-tools flex items-center  gap-2">
          <ProfileInfo />
          {!isDesktop && <MobileMenuHandler />}
        </div>
      </div>
    </ClassicHeader>
  )
}

export default Header
