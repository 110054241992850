import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { siteConfig } from '@/config/site.config'

interface SidebarState {
  collapsed: boolean
  setCollapsed: (v: boolean) => void
  sidebarType: string
  setSidebarType: (v: string) => void
  subMenu: boolean
  setSubmenu: (v: boolean) => void
  sidebarBg: string
  setSidebarBg: (v: string) => void
  mobileMenu: boolean
  setMobileMenu: (v: boolean) => void
}

export const useSidebarStore = create<SidebarState>()(
  persist(
    (set) => ({
      collapsed: false,
      setCollapsed: (value) => set({ collapsed: value }),
      sidebarType:
        siteConfig.layout === 'semi-box' ? 'popover' : siteConfig.sidebarType,
      setSidebarType: (value) => {
        set({ sidebarType: value })
      },
      subMenu: false,
      setSubmenu: (value) => set({ subMenu: value }),
      sidebarBg: siteConfig.sidebarBg,
      setSidebarBg: (value) => set({ sidebarBg: value }),
      mobileMenu: false,
      setMobileMenu: (value) => set({ mobileMenu: value }),
    }),
    { name: 'sidebar-store', storage: createJSONStorage(() => localStorage) },
  ),
)

export const usePublisherAccountsStore = create((set) => ({
  data: [],
  setData: (data: any) => set({ data }),
  page: 1,
  setPage: (page: number) => set({ page }),
}))
