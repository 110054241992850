'use client'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import CampaignActionsButton from '@/components/domain/campaign/actions-button'
import CampaignStatus from '@/components/domain/campaign/status'
import Date from '@/components/domain/common/date'
import Money from '@/components/domain/common/money'
import { DataTableEmptyRow } from '@/components/shared/data-table/data-table-empty-row'

const DataTable = ({ data, onRowClick, isLoading }: any) => {
  const columns: { key: string; label: string; width: string }[] = [
    {
      key: 'id',
      label: 'ID',
      width: '70px',
    },
    {
      key: 'country',
      label: 'Country',
      width: '75px',
    },
    {
      key: 'name',
      label: 'Name',
      width: '400px',
    },
    {
      key: 'budget',
      label: 'Budget',
      width: '100px',
    },
    {
      key: 'advertiserId',
      label: 'Advertiser',
      width: '150px',
    },
    {
      key: 'ownerId',
      label: 'Owner',
      width: '150px',
    },
    {
      key: 'createdAt',
      label: 'Create Date',
      width: '150px',
    },
    {
      key: 'status',
      label: 'Status',
      width: '100px',
    },
    {
      key: 'action',
      label: '',
      width: '40px',
    },
  ]

  if (!isLoading) {
    return (
      <Table className="table-fixed">
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHead
                style={{ width: column.width }}
                className={cn(
                  'px-0',
                  column.key === 'action' && 'sticky-right',
                  'cursor-default py-0 h-10',
                )}
                key={column.key}>
                <span className="h-8 p-2">{column.label}</span>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.data.map((item: any) => (
            <TableRow
              key={item.id}
              className="h-1 border-gray-200 py-0 hover:cursor-pointer hover:bg-gray-400">
              <TableCell
                className="overflow-hidden truncate px-[0.4rem] py-[0.6rem]"
                onClick={() => onRowClick(item)}>
                {item.id}
              </TableCell>
              <TableCell
                className="overflow-hidden truncate px-[0.4rem] py-[0.6rem]"
                onClick={() => onRowClick(item)}>
                {item.i18n.country || ''}
              </TableCell>
              <TableCell
                className="overflow-hidden truncate px-[0.4rem] py-[0.6rem]"
                onClick={() => onRowClick(item)}>
                {item.name}
              </TableCell>
              <TableCell
                className="overflow-hidden truncate px-[0.4rem] py-[0.6rem]"
                onClick={() => onRowClick(item)}>
                <Money currency={item.i18n.currency} locale={item.i18n.locale}>
                  {item.budget}
                </Money>
              </TableCell>
              <TableCell
                className="overflow-hidden truncate px-[0.4rem] py-[0.6rem]"
                onClick={() => onRowClick(item)}>
                {item.brand}
              </TableCell>
              <TableCell
                className="overflow-hidden truncate px-[0.4rem] py-[0.6rem]"
                onClick={() => onRowClick(item)}>
                {item.owner?.name || ''}
              </TableCell>
              <TableCell
                className="overflow-hidden truncate px-[0.4rem] py-[0.6rem]"
                onClick={() => onRowClick(item)}>
                <Date
                  timezone={item.i18n?.timezone}
                  locale={item.i18n?.locale}
                  dateOnly>
                  {item.createdAt}
                </Date>
              </TableCell>
              <TableCell
                className="overflow-hidden truncate px-[0.4rem] py-[0.6rem]"
                onClick={() => onRowClick(item)}>
                <CampaignStatus status={item.status} size={3} />
              </TableCell>
              <TableCell className="sticky-right">
                <CampaignActionsButton
                  customItems={
                    [
                      // temporarily disabling this action as not implemented yet
                      // TODO: Uncomment once edit Campaign is ready
                      // {
                      //   label: 'Edit Campaign',
                      //   icon: 'uil:edit',
                      //   action: () => router.push(`/campaigns/${item.id}?triggerAction=edit`)
                      // }
                    ]
                  }
                  className="shadow-none"
                  campaign={item}
                />
              </TableCell>
            </TableRow>
          ))}
          {data.data.length === 0 && (
            <DataTableEmptyRow colSpan={columns.length} />
          )}
        </TableBody>
      </Table>
    )
  }

  return (
    <Table className="w-full">
      <TableHeader>
        <TableRow>
          {columns.map((column) => (
            <TableHead
              style={{ width: column.width }}
              className={cn(
                'px-0',
                column.key === 'action' && 'sticky',
                'cursor-default py-0 h-10',
              )}
              key={column.key}>
              <Button
                variant="ghost"
                className="flex h-8 p-2 data-[state=open]:bg-muted">
                {column.label}
              </Button>
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.from({ length: 20 }, (_, index) => index + 1).map((item) => (
          <TableRow key={item} className="h-1 py-0 hover:bg-gray-400">
            {Array.from({ length: 9 }, (_, index) => index + 1).map((item2) => (
              <TableCell key={item2} className="overflow-hidden truncate py-4">
                <Skeleton className="h-2 w-full" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default DataTable
