'use client'

import React, { useState } from 'react'
import { usePathname } from 'next/navigation'
import { useSidebarStore, useThemeStore } from '@/store'

import { menusConfig } from '@/config/menu.config'
import { cn, getDynamicPath, isLocationMatch } from '@/lib/utils'
import { ScrollArea } from '@/components/ui/scroll-area'

import SidebarLogo from '../common/logo'
import MenuLabel from '../common/menu-label'
import NestedSubMenu from '../common/nested-menus'
import CollapseMenu from './collapse-menu'
import SingleMenuItem from './single-menu-item'
import SubMenuHandler from './sub-menu-handler'

const menus = menusConfig || []

const PopoverSidebar = () => {
  const { collapsed, sidebarBg } = useSidebarStore()
  const { layout } = useThemeStore()
  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null)
  const [activeMultiMenu, setMultiMenu] = useState<number | null>(null)

  const toggleSubmenu = (i: number) => {
    if (activeSubmenu === i) {
      setActiveSubmenu(null)
    } else {
      setActiveSubmenu(i)
    }
  }

  const toggleMultiMenu = (subIndex: number) => {
    if (activeMultiMenu === subIndex) {
      setMultiMenu(null)
    } else {
      setMultiMenu(subIndex)
    }
  }

  const pathname = usePathname()
  const locationName = getDynamicPath(pathname)

  React.useEffect(() => {
    let subMenuIndex = null
    let multiMenuIndex = null
    menus?.map((item: any, i: number) => {
      if (item?.child) {
        item.child.map((childItem: any, j: number) => {
          if (isLocationMatch(childItem.href, locationName)) {
            subMenuIndex = i
          }
          if (childItem?.multi_menu) {
            childItem.multi_menu.map((multiItem: any, k: number) => {
              if (isLocationMatch(multiItem.href, locationName)) {
                subMenuIndex = i
                multiMenuIndex = j
              }
            })
          }
        })
      }
    })
    setActiveSubmenu(subMenuIndex)
    setMultiMenu(multiMenuIndex)
  }, [locationName])

  // menu title

  return (
    <div
      className={cn('fixed  top-0  border-r  ', {
        'w-[248px]': !collapsed,
        'w-[72px]': collapsed,
        'm-6 bottom-0   bg-card rounded-md': layout === 'semibox',
        'h-full bg-card': layout !== 'semibox',
      })}>
      {sidebarBg !== 'none' && (
        <div
          className="absolute left-0 top-0   z-[-1] size-full bg-cover bg-center opacity-[0.07]"
          style={{ backgroundImage: `url(${sidebarBg})` }}></div>
      )}
      <CollapseMenu />
      <SidebarLogo />
      <ScrollArea
        className={cn('sidebar-menu h-[calc(100%-80px)] ', {
          'px-2': !collapsed,
        })}>
        <ul
          className={cn('space-y-1', {
            'space-y-2 text-center': collapsed,
          })}>
          {menus.map((item, i) => (
            <li key={`menu_key_${i}`}>
              {/* single menu  */}

              {!item.child && !item.isHeader && (
                <SingleMenuItem item={item} collapsed={collapsed} />
              )}

              {/* menu label */}
              {item.isHeader && !item.child && !collapsed && (
                <MenuLabel item={item} />
              )}

              {/* sub menu */}
              {item.child && (
                <>
                  <SubMenuHandler
                    item={item}
                    toggleSubmenu={toggleSubmenu}
                    index={i}
                    activeSubmenu={activeSubmenu}
                    collapsed={collapsed}
                    menuTitle={item.title}
                  />
                  {!collapsed && (
                    <NestedSubMenu
                      toggleMultiMenu={toggleMultiMenu}
                      activeMultiMenu={activeMultiMenu}
                      activeSubmenu={activeSubmenu}
                      item={item}
                      index={i}
                    />
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </ScrollArea>
    </div>
  )
}

export default PopoverSidebar
