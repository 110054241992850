import { z } from 'zod'

import { countries } from '@/constants/countries.constant'

export enum CampaignType {
  Advertiser = 1,
  Publisher = 2,
}

export enum CampaignStatusEnum {
  Draft = 0,
  Pending = 1,
  Active = 5,
  Finished = 10,
  Rejected = 99,
  Archived = 100,
}

export enum CampaignPublisherBrandStatusEnum {
  Pitched = 'pitched',
  Accepted = 'accepted',
  Declined = 'declined',
  OptedOut = 'optedOut',
}

export enum CampaignChargeType {
  CPM = 'CPM',
  CPC = 'CPC',
}

export const CountryEnum = z.enum(countries)

export enum CampaignPlacement {
  Core = 0,
  Pubfeed = 1,
  Social = 2,
  Newsletter = 3,
}
