'use client'

import { useRef } from 'react'
import { Icon } from '@iconify/react'
import { Check } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

const SearchFilter = ({
  filter,
  label,
  value,
  onSelected,
  options,
  isLoading = true,
}: {
  filter: string
  label: string
  value?: string
  onSelected: (key: any, value: any) => void
  options: { label: string; value: string }[]
  isLoading: boolean
}) => {
  const commandListRef = useRef<HTMLDivElement>(null)
  const scroller = useRef<ReturnType<typeof setTimeout>>(null)

  if (isLoading) {
    return (
      <Button
        variant="filterButton"
        className={cn(
          'w-full md:w-auto text-default-500 hover:text-default-500 border justify-normal text-[0.775rem] rounded-full p-0 h-6 px-1  hover:bg-[#f5f6f8] border-[#d8dee4] bg-white',
          'border-dashed',
        )}>
        <span>{label}</span>
        <Icon icon="line-md:loading-loop" className="ml-2" />
      </Button>
    )
  }

  const buttonLabel =
    value && options.find((o) => String(o.value) === String(value))
      ? `${options.find((o) => String(o.value) === String(value))?.label || ''}`
      : ''

  return (
    <Popover key={filter}>
      <PopoverTrigger asChild>
        <Button
          variant="filterButton"
          className={cn(
            'w-full md:w-auto text-default-500 hover:text-default-500 border justify-normal text-[0.775rem] rounded-full p-0 h-6 pl-1 pr-2  hover:bg-[#f5f6f8] border-[#d8dee4] bg-white',
            value ? 'border' : 'border-dashed',
          )}>
          {value && (
            <Icon
              className="mr-[0.35rem] size-4"
              icon="radix-icons:cross-circled"
              onClick={(e) => {
                e.preventDefault()
                onSelected(filter, null)
              }}
            />
          )}
          {buttonLabel ? (
            <>
              {label}
              <span className="ml-2 border-l pl-2 text-linkby-purple">
                {buttonLabel}
              </span>
            </>
          ) : (
            <>
              <Icon
                className="mr-[0.35rem] size-4"
                icon="radix-icons:plus-circled"
              />
              {label}
            </>
          )}
          {buttonLabel && <Icon icon="radix-icons:caret-down" width={24} />}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px] p-0" align="start">
        <Command>
          <CommandInput
            placeholder="Search..."
            onValueChange={() => {
              clearTimeout(scroller.current as ReturnType<typeof setTimeout>)
              scroller.current = setTimeout(() => {
                commandListRef.current?.scrollTo({ top: 0 })
              }, 1)
            }}
          />
          <CommandList ref={commandListRef}>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {[...(options || [])]
                .sort((prev, next) => prev.label.localeCompare(next.label))
                .map((option) => (
                  <CommandItem
                    key={option.value}
                    onSelect={() => onSelected(filter, option.value)}>
                    <span className="w-full truncate">{option.label}</span>
                    <span className="ml-4 text-gray-400">#{option.value}</span>
                    {value === option.value && (
                      <Check className={cn('h-4 w-4')} />
                    )}
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default SearchFilter
