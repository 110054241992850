export const siteConfig: SiteConfig = {
  name: 'Linkby Admin',
  description: null,
  theme: 'zinc',
  layout: 'vertical',
  hideSideBar: false,
  sidebarType: 'popover',
  sidebarColor: null,
  navbarType: 'sticky',
  footerType: 'static',
  sidebarBg: 'none',
  radius: 0,
}

type SiteConfig = {
  name: string
  theme: string
  layout: 'vertical' | 'horizontal' | 'semi-box'
  hideSideBar: boolean
  sidebarType: 'popover' | 'classic' | 'module'
  navbarType: 'sticky' | 'floating' | 'static'
  footerType: 'sticky' | 'static' | 'hidden'
  sidebarBg: string
  radius: number
  sidebarColor: string | null
  description: string | null
}
