/**
 * Formats a number as currency based on the given locale.
 * @param amount The number to format as currency.
 * @param locale The locale to use for formatting (e.g., 'en-US', 'de-DE', 'ja-JP').
 * @param currency The currency code to use (e.g., 'USD', 'EUR', 'JPY').
 *                 If not provided, it will use the default currency for the locale.
 * @param options
 * @returns A string representing the formatted currency.
 */
export function formatCurrency(
  amount: string | number,
  locale: string,
  currency: string,
  options?: { trim?: boolean },
): string {
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(Number(amount))

  if (options?.trim) {
    return formattedAmount.replace(/\.00$/, '')
  }

  return formattedAmount
}

export const formatNumber = (
  value: string | number,
  locale: string,
): string => {
  const v = Number(value)
  return isNaN(v) ? '-' : v.toLocaleString(locale)
}

/**
 * Helper function to get the timezone abbreviation.
 * @param date The date object to extract the timezone from.
 * @param timezone The timezone to use for formatting (e.g., 'America/New_York', 'Europe/London').
 * @param locale time locale of the specified timezone
 * @returns A string representing the timezone abbreviation.
 */
function getTimeZoneAbbr(date: Date, timezone: string, locale: string): string {
  return date
    .toLocaleTimeString(locale, { timeZoneName: 'short', timeZone: timezone })
    .split(' ')
    .pop() as string
}

export function getTimeZoneAbbrFromEpoch(
  date: number,
  timezone: string,
  locale: string,
): string {
  return formatDate(date, timezone, locale).split(' ').slice(-1)[0] as string
}

/**
 * Formats a date based on the given locale and timezone.
 * @param date The date to format (as a number or Date object).
 * @param locale The locale to use for formatting (e.g., 'en-US', 'de-DE', 'ja-JP').
 * @param timezone The timezone to use for formatting (e.g., 'America/New_York', 'Europe/London').
 * @param options Additional options for formatting.
 *                 - dateOnly: If true, only the date will be displayed.
 *                 - showTimezone: If false, the timezone will not be displayed.
 * @returns A string representing the formatted date in the format 'dd MMM yyyy, h:mm a (AEDT)'.
 */
export function formatDate(
  date: string | number | Date,
  timezone: string,
  locale: string,
  options?: { dateOnly?: boolean; showTimezone?: boolean },
): string {
  const dateObject =
    typeof date === 'number' || typeof date === 'string' ? new Date(date) : date

  const formatLocale = Intl.DateTimeFormat().resolvedOptions().locale

  const formatter = new Intl.DateTimeFormat(formatLocale, {
    timeZone: timezone,
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: options?.dateOnly ? undefined : 'numeric',
    minute: options?.dateOnly ? undefined : '2-digit',
    hour12: true,
  })

  const formattedDate = formatter.format(dateObject)

  if (options?.showTimezone === false) {
    return formattedDate
  }

  const timezoneName = getTimeZoneAbbr(dateObject, timezone, locale)

  return `${formattedDate} (${timezoneName})`
}

/**
 * Formats a given date in 24-hour format (e.g., 13:23).
 * @param date The date to format (as a number or Date object).
 * @param timezone The timezone to use for formatting (e.g., 'America/New_York', 'Europe/London').
 * @param locale The locale to use for formatting (e.g., 'en-US', 'de-DE', 'ja-JP').
 * @returns A string representing the formatted time in 24-hour format.
 */
export function formatTime24Hour(
  date: string | number | Date,
  timezone: string,
  locale: string,
): string {
  const dateObject =
    typeof date === 'number' || typeof date === 'string' ? new Date(date) : date

  const formatter = new Intl.DateTimeFormat(locale, {
    timeZone: timezone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  return formatter.format(dateObject)
}

/**
 * Formats a date range based on the given locale and timezone.
 * @param startDate The start date to format (as a number or Date object).
 * @param endDate The end date to format (as a number or Date object).
 * @param locale The locale to use for formatting (e.g., 'en-US', 'de-DE', 'ja-JP').
 * @param timezone The timezone to use for formatting (e.g., 'America/New_York', 'Europe/London').
 * @param options Additional options for formatting.
 *                 - dateOnly: If true, only the date will be displayed.
 *                 - showTimezone: If false, the timezone will not be displayed.
 * @returns A string representing the formatted date range.
 */
export function formatDateRange(
  startDate: string | number | Date,
  endDate: string | number | Date,
  timezone: string,
  locale: string,
  options?: {
    dateOnly?: boolean
    showTimezone?: boolean
    textInBetween?: string
  },
): string {
  const formattedStartDate = formatDate(startDate, timezone, locale, options)
  const formattedEndDate = formatDate(endDate, timezone, locale, options)
  const textInBetween = options?.textInBetween ?? '~'

  if (options?.showTimezone === false) {
    return `${formattedStartDate} ${textInBetween} ${formattedEndDate}`
  }

  const timezoneName = getTimeZoneAbbr(new Date(endDate), timezone, locale)

  if (formattedStartDate.endsWith(`(${timezoneName})`)) {
    return `${formattedStartDate.replace(` (${timezoneName})`, '')} ${textInBetween} ${formattedEndDate}`
  }

  return `${formattedStartDate} ${textInBetween} ${formattedEndDate}`
}
