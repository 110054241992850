'use client'

import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible'

import SubMenuItem from './sub-menu-item'

const NestedSubMenu = ({
  activeSubmenu,
  item,
  index,
}: {
  activeSubmenu: number | null
  item: any
  index: number
}) => {
  return (
    <Collapsible open={activeSubmenu === index}>
      <CollapsibleContent className="CollapsibleContent">
        <ul className="sub-menu relative space-y-4">
          {item.child?.map((subItem: any, j: number) => (
            <li
              className="relative block pl-10 before:absolute first:pt-2 last:pb-1"
              key={`sub_menu_${j}`}>
              <SubMenuItem subItem={subItem} />
            </li>
          ))}
        </ul>
      </CollapsibleContent>
    </Collapsible>
  )
}

export default NestedSubMenu
