'use client'

import { usePathname } from 'next/navigation'

import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible'

import MultiMenuHandler from './multi-menu-handler'
import MultiNestedMenu from './multi-nested-menu'
import SubMenuItem from './sub-menu-item'

const NestedSubMenu = ({
  activeSubmenu,
  item,
  index,
  activeMultiMenu,
  toggleMultiMenu,
  title,
}: {
  activeSubmenu: number | null
  item: any
  index: number
  activeMultiMenu: number | null
  toggleMultiMenu: (index: number) => void
  title?: string
}) => {
  const pathname = usePathname()
  return (
    <Collapsible open={activeSubmenu === index}>
      <CollapsibleContent className="CollapsibleContent">
        <ul className="sub-menu relative space-y-4">
          {item.child?.map((subItem: any, j: number) => (
            <li
              className="relative block pl-10 before:absolute first:pt-4 last:pb-1"
              key={`sub_menu_${j}`}>
              {subItem?.multi_menu ? (
                <div>
                  <MultiMenuHandler
                    subItem={subItem}
                    subIndex={j}
                    activeMultiMenu={activeMultiMenu}
                    toggleMultiMenu={toggleMultiMenu}
                  />
                  <MultiNestedMenu
                    subItem={subItem}
                    subIndex={j}
                    activeMultiMenu={activeMultiMenu}
                  />
                </div>
              ) : (
                <SubMenuItem subItem={subItem} />
              )}
            </li>
          ))}
        </ul>
      </CollapsibleContent>
    </Collapsible>
  )
}

export default NestedSubMenu
