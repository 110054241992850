import { forwardRef } from 'react'
import Cleave from 'cleave.js/react'
import { useFormContext } from 'react-hook-form'

interface MoneyInputProps {
  currencySymbol: string
  className?: string
  name: string
}

const MoneyInput = forwardRef<HTMLInputElement, MoneyInputProps>(
  ({ currencySymbol, className, name, ...props }, ref) => {
    const {
      register,
      setValue,
      formState: { errors },
    } = useFormContext()

    const hasError = !!errors[name]

    return (
      <Cleave
        placeholder={`Enter amount in ${currencySymbol}`}
        options={{
          prefix: `${currencySymbol} `,
          numeral: true,
          numeralDecimalMark: '.',
          delimiter: ',',
          numeralPositiveOnly: true,
        }}
        {...register(name)}
        className={`block w-full rounded-md border p-1.5 shadow-sm disabled:opacity-50 ${
          hasError
            ? 'border-red-500 focus-visible:outline-red-500'
            : 'border-gray-300'
        } ${className}`}
        {...props}
        onChange={(e) =>
          setValue(name, e.target.value.replace(/[^0-9.-]+/g, ''))
        }
      />
    )
  },
)

MoneyInput.displayName = 'MoneyInput'

export default MoneyInput
