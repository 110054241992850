'use client'

import { LinkbyLogo } from '@/components/svg'

const LayoutLoader = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center space-y-2">
      <LinkbyLogo className="size-48 text-primary" />
    </div>
  )
}

export default LayoutLoader
