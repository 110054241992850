import { useState } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'

import { useApproveCampaignMutation } from '@/services/campaigns/mutation.service'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { toast } from '@/components/ui/use-toast'

import Loader from '../../common/loader'
import { ActionButtonComponent } from '../actions-button'

const ApproveCampaignDialog: React.FC<ActionButtonComponent> = ({
  campaign,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onSuccess = () => {
    toast({
      title: 'Campaign approved',
      color: 'success',
      duration: 5000,
    })
    setIsOpen(false)
  }

  const onError = () => {
    toast({
      title:
        'Failed to approve campaign. Please contact tech support if issue persists.',
      color: 'destructive',
    })
    setIsOpen(false)
  }

  const {
    mutate: approveCampaignMutation,
    isPending,
    isSuccess,
  } = useApproveCampaignMutation({
    campaign,
    onSuccess,
    onError,
  })

  const triggerApprove = () => approveCampaignMutation()

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogTitle>Approve Campaign</DialogTitle>
        <p>
          Do you want to approve the campaign <strong>{campaign.name}</strong>?
        </p>
        <DialogFooter>
          <div className="flex-end flex space-x-2">
            <DialogClose asChild>
              <Button color="default" size="sm" variant="raised">
                Cancel
              </Button>
            </DialogClose>
            <Button
              color={isSuccess ? 'success' : 'action'}
              className="relative"
              size="sm"
              variant="raised"
              onClick={triggerApprove}
              disabled={isPending}>
              <span className={isPending ? 'invisible' : ''}>
                {isSuccess ? 'Approved' : 'Approve'}
              </span>
              {isPending ? (
                <Loader size={1} className="absolute min-h-full" />
              ) : null}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ApproveCampaignDialog
