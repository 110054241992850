import { Campaign } from '@/schemas/campaign.schema'

const getPlatformSettingsByCountry = (
  affiliate: any,
  platform: any,
  country = 'default',
) => {
  if (affiliate && affiliate.platforms && affiliate.platforms[platform]) {
    if (affiliate.platforms[platform].hasOwnProperty(country)) {
      return Object.keys(affiliate.platforms[platform][country] || {}).length
        ? affiliate.platforms[platform][country]
        : null
    } else if (affiliate.platforms[platform].hasOwnProperty('default')) {
      return Object.keys(affiliate.platforms[platform]['default'] || {}).length
        ? {
            ...affiliate.platforms[platform]['default'],
            isDefault: true,
          }
        : null
    } else {
      return null
    }
  }
  return null
}

function clearEmpty(target: any) {
  for (const key in target) {
    if (!target[key] || typeof target[key] !== 'object') {
      continue
    }

    clearEmpty(target[key])
    if (Object.keys(target[key]).length === 0) {
      delete target[key]
    }
  }

  return target
}

export const getActiveAffiliatePlatforms = (campaign: Campaign) => {
  const affiliatePlatforms = campaign?.affiliate?.platforms ?? {}
  Object.keys(affiliatePlatforms).map((platform) => {
    const platformSettings = getPlatformSettingsByCountry(
      campaign.affiliate,
      platform,
      campaign.i18n.country,
    )
    if (!platformSettings) {
      delete affiliatePlatforms[platform]
    }
  })
  return clearEmpty(affiliatePlatforms)
}
