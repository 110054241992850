import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Icon } from '@iconify/react'
import * as Tooltip from '@radix-ui/react-tooltip'

import { cn, getDynamicPath, isLocationMatch } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'

const SingleMenuItem = ({
  item,
  collapsed,
}: {
  item: any
  collapsed: boolean
}) => {
  const { badge, href, title, disabled } = item

  const pathname = usePathname()
  const locationName = getDynamicPath(pathname)
  return (
    <Link href={disabled ? '#' : href}>
      <>
        {collapsed ? (
          <div>
            <Tooltip.Provider delayDuration={0}>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <span
                    className={cn(
                      'h-10 w-10 mx-auto rounded-md transition-all duration-300 inline-flex flex-col items-center justify-center relative',
                      {
                        'text-linkby-purple': isLocationMatch(
                          href,
                          locationName,
                        ),
                        ' text-default-600 hover:bg-background-hover hover:text-linkby-purple':
                          !isLocationMatch(href, locationName) && !disabled,
                        ' bg-transparent data-[state=delayed-open]:bg-transparent cursor-not-allowed text-default-700/50':
                          disabled,
                      },
                    )}>
                    <Icon
                      className={cn(
                        'w-[20px] h-[20px] flex items-center justify-center',
                        disabled ? 'opacity-50' : 'opacity-100',
                      )}
                      icon={item.icon}
                    />
                  </span>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  {!disabled && (
                    <Tooltip.Content
                      side="right"
                      className={cn(
                        'select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none text-linkby-purple shadow-sm will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade dark:bg-muted',
                      )}
                      sideOffset={5}>
                      {title}
                      <Tooltip.Arrow className="fill-linkby-purple" />
                    </Tooltip.Content>
                  )}
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        ) : (
          <div
            className={cn(
              'flex gap-3 text-default-700 text-sm capitalize px-[10px] font-medium py-2 rounded hover:bg-background-menu',
              disabled
                ? 'cursor-not-allowed text-default-700/50'
                : 'cursor-pointer hover:text-linkby-purple hover:bg-background-hover transition-all duration-300',
              {
                'text-linkby-purple': isLocationMatch(href, locationName),
              },
            )}>
            <span className="flex grow-0 items-center justify-center">
              <Icon
                className="flex size-[20px] items-center justify-center"
                icon={item.icon}
              />
            </span>
            <div
              className={cn('text-box flex-grow', {
                'font-semibold': isLocationMatch(href, locationName),
              })}>
              {title}
            </div>
            {badge && <Badge className="rounded">{item.badge}</Badge>}
          </div>
        )}
      </>
    </Link>
  )
}

export default SingleMenuItem
